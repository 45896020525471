@import 'assets/css/variables';

.padding-wrapper {
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 24px;
    margin-left: 80px;
    background: $layout-main-bg;
    .ant-row{
      flex: 1;
    }
  }