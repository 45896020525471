@import 'assets/css/variables';

.items-uom-right-block {
  .ant-collapse-item:last-child > .ant-collapse-content {
    background: $view-items-df-bg;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    padding: 4px;
  }
}
.item-external-ids-block-right-card {
  .ant-collapse-content .ant-collapse-content-box {
    padding: 0;
    .item-add-button-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .button-wrapper {
    width: 100px;
    height: 40px;
    margin-top: 16px;
    .ant-btn {
      height: 40px;
    }
  }
  .ant-collapse-item:last-child .ant-collapse-content {
    padding: 17px 22px;
  }
}
.no-external-ids-block {
  width: 100%;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  color: $view-items-external-ids-block-color;
}

.items-info-right-block {
  .ant-collapse-content > .ant-collapse-content-box{
    padding: 4px;
  }
}

@media (max-width: 1550px) {
  .items-info-right-block {
    .ant-collapse-content > .ant-collapse-content-box{
      padding: 14px;
    }
  }
}
