@import 'assets/css/variables';

.locations-legenda-containder {
  display: flex;
  align-items: center;
  padding: 14px 60px 24px 48px;
  position: fixed;
  background: $location-legenda-df-bg;
  z-index: 3;
  span {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: $location-legenda-df-color;
    margin: 0 8px 0 4px;
  }
  .color-block {
    display: flex;
    border-radius: 5px;
    width: 25px;
    height: 25px;
  }
  .legenda-block1 {
    display: flex;
    div {
      background: $location-legenda-block1-bg;
    }
  }
  .legenda-block2 {
    display: flex;
    div {
      background: $location-legenda-block2-bg;
    }
  }
  .legenda-block3 {
    display: flex;
    div {
      background: $location-legenda-block3-bg;
    }
  }
  .legenda-block4 {
    display: flex;
    div {
      background: $location-legenda-block4-bg;
    }
  }
  .legenda-block5 {
    display: flex;
  }
  .legenda-block6 {
    display: flex;
  }
}
.with-modal{
  display: block;
  padding: 0;
  position: initial;
}
@media (max-width: 900px) {
  .locations-legenda-containder{
    display: block;
  }
}

@media (max-width: 1400px) {
  .with-right-block{
    display: block;
  }
}

