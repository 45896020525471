@import 'assets/css/variables';

.custom-upload-image-wrapper {
  width: 100%;
  .ant-upload-picture-card-wrapper {
    width: 100%;
    .ant-upload.ant-upload-select-picture-card {
      border: 2px solid $upload-img-border-picture-card;
      border-radius: 10px;
      background: transparent;
      width: 100%;
      height: 116px;
      .upload-image-button {
        font-family: 'Poppins';
        font-weight: 300;
        font-size: 12px;
        color: $upload-img-picture-card-color-btn;
        margin-top: 10px;
      }
      .anticon {
        display: none;
      }
      .ant-upload {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 8px;
        }
      }
    }
  }
}

.readonly-upload-image-container {
  .ant-upload-picture-card-wrapper {
    cursor: default;

    .ant-upload.ant-upload-disabled {
      cursor: default !important;
      background: transparent;
      color: $input-color !important;
      border-color: $input-border;
    }
  }
}

@media (max-width: 1550px) {
  .custom-upload-file-wrapper {
    .ant-upload-picture-card-wrapper {
      .ant-upload.ant-upload-select-picture-card {
        height: 90px;
        .upload-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .upload-image-button {
            margin: 0;
            img{
              width: 50%;
              height: 50%;
            }
          }
        }
      }
    }
  }
}
