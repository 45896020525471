@import 'assets/css/variables';

.related-user-card-header {
  margin-bottom: 16px;
  // padding-left: 24px;
  padding: 0 24px;
  div {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    color: $store-related-card-df-color;
  }
}

.related-relative-col {
  position: relative;
  .delete-related-user-block {
    position: absolute;
    right: -6px;
    top: 4px;
    cursor: pointer;
  }
}
.add-related-user-button {
  width: 123px;
  height: 40px;
  float: right;
  .ant-btn {
    height: 40px;
  }
}
