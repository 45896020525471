@import 'assets/css/variables';

.sidebar-wrapper {
  position: relative;
  .hide-text {
    width: calc(100% - 40px);
    height: 44px;
    text-align: center;
    position: absolute;
    top: 17px;
    left: 20px;
    color: $sidebar-wrapper-color;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
  }

  .sidebar {
    width: 80px;
    background: $sidebar-wrapper-bg;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: $sidebar-wrapper-box-shadow;
    height: 100%;
    z-index: 100;
    position: fixed;
    padding-right: 20px;
    padding-bottom: 30px;
    .ant-layout-sider-children {
      height: calc(100% - 48px);
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .user-block {
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-left: 20px;
        height: 60px;
        .avatar-block {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 40px;
          border-radius: 50%;
          background: $sidebar-wrapper-bg-avatar-block;
          img,
          svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .user-options-block {
          margin-left: 16px;
          .name-container {
            display: flex;
            align-items: center;
            .user-name {
              font-weight: 500;
              font-size: 16px;
              color: $sidebar-wrapper-color;
              margin-right: 16px;
            }
            svg {
              cursor: pointer;
            }
          }
          .logout-button {
            border: 0.5px solid $sidebar-wrapper-logout-border;
            border-radius: 7px;
            background: transparent;
            cursor: pointer;
            padding: 2px 10px;
          }
        }
      }
    }
    .ant-layout-sider-trigger {
      top: 0px;
      left: 0;
      width: 80px;
      background: transparent;
      span {
        position: absolute;
        top: 20px;
        left: 30px;
        svg {
          fill: $sidebar-wrapper-svg-fill-color;
          font-size: 20px;
        }
      }
    }
    .ant-menu-inline,
    .ant-menu-vertical {
      border: none;
      background: transparent;
    }
    .ant-menu {
      position: relative;
      .select-arrow {
        position: absolute;
        left: 0px;
        z-index: 200;
        width: 5px;
        height: 40px;
        background: $sidebar-wrapper-svg-fill-color;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
      .ant-menu-item {
        padding: 0 !important;
        &::after {
          border: none;
        }
        &:hover {
          .sidebar-item {
            font-weight: 600;
          }
        }
        &:active {
          background: transparent;
        }
        .ant-menu-title-content {
          padding-left: 20px;
        }
        .sidebar-item {
          padding-left: 40px;
          font-weight: 400;
          font-size: 16px;
          color: $sidebar-wrapper-color;
          opacity: 0.8;
        }
        .sidebar-items-header {
          width: 100%;
          font-family: 'Poppins';
          font-weight: 300;
          font-size: 14px;
          color: $sidebar-wrapper-items-color;
          border-top: 1px solid $sidebar-wrapper-border-item;
        }
      }
      .aligned-item {
        .ant-menu-title-content {
          display: flex;
          align-items: center;
        }
      }
      .ant-menu-item-selected {
        background: transparent;
        .sidebar-item {
          font-weight: 600;
        }
      }
    }
  }
  .collapsed-sidebar{
    padding-right: 10px;
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-arrow-content{
      --antd-arrow-background-color: $sidebar-antd-bg-content-inner;
    }
    .ant-tooltip-inner {
      display: flex;
      align-items: center;
      background: $sidebar-antd-bg-content-inner;
      border-radius: 5px;
      span {
        margin-left: 12px;
        color: $dark-text;
      }
    }
  }
}
