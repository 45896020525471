@import 'assets/css/variables';

.rack-wrapper {
  display: flex;
  padding: 16px 40px 24px 0;
  background: $location-rack-wrapper-bg;
  border: 1px solid $location-rack-wrapper-border;
  box-shadow: $location-rack-wrapper-box-shadow;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  max-width: 720px;
  width: fit-content;
  &::-webkit-scrollbar {
    height: 15px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: $location-rack-scrollbar-thumb-bg;
    box-shadow: $location-rack-scrollbar-thumb-box-shadow;
    border-radius: 5px;
  }
  .rack {
    display: flex;
    flex-direction: column;
    .ant-popover-placement-rightBottom .ant-popover-arrow {
      bottom: 9px;
    }
    .rack-title {
      margin-left: 40px;
      padding: 10px 10px 5px;
      font-weight: 700;
      font-size: 18px;
      font-family: 'Poppins';
      display: inline-block;
      cursor: pointer;
      width: fit-content;
      max-width: 250px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      user-select: none;
      border-radius: 5px;
      &:hover {
        background: $location-bin-rack-title-bg-hover;
      }
      &:active {
        background: $location-bin-rack-title-bg-active;
      }
    }
    .selected-rack-title {
      background: $location-bin-rack-selected-title-bg;
    }
    .rack-row {
      display: flex;
    }
    .cell {
      border-radius: 5px;
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;
      background: $location-bin-rack-cell-bg;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      margin: 2px;
      box-sizing: border-box;
      max-width: 893px;
      overflow: visible;
      position: relative;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      .items-circle {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid $location-bin-rack-cell-items-circle-border;
        background: $location-bin-rack-cell-items-circle-bg;
        position: absolute;
        bottom: -2px;
        right: -2px;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 10px;
        color: $location-bin-rack-cell-items-circle-color;
        &:hover {
          border-color: $location-bin-rack-cell-items-circle-border-hover;
          .legenda-block-wrapper {
            display: block;
          }
        }
      }
    }
    .hidden {
      visibility: hidden;
    }
    .modal-column,
    .modal-level {
      cursor: default;
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2px;
      &:hover {
        background: transparent !important;
      }
      &:active {
        background: transparent !important;
      }
      &.selected {
        background: transparent !important;
      }
    }
    .noborder {
      cursor: pointer;
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2px;
      border: none;
      border-radius: 50%;
      background: transparent;
      &:hover {
        background: $locations-bin-rack-cell-noborder-hover;
      }
      &:active {
        background: $locations-bin-rack-cell-noborder-active;
      }
      &.selected {
        background: $locations-bin-rack-cell-noborder-selected;
        background-image: none;
      }
    }
    .level {
      margin-right: 3px;
    }
    .column {
      margin-bottom: 3px;
    }
    .rack-not-available {
      background: $locations-bin-rack-not-available-bg;
      &:hover,  &.selected {
        box-shadow: inset 0px 12px 25px $locations-bin-rack-not-available-box-shadow;
      }
    }
    .rack-ocuped {
      background: $locations-bin-rack-ocuped-bg;
      &:hover, &.selected {
        box-shadow: inset 0px 12px 25px $locations-bin-rack-selected;
      }
    }
    .rack-available {
      background: $locations-bin-rack-available-bg;
      &:hover, &.selected {
        box-shadow: inset 0px 12px 25px $locations-bin-rack-available-bg-box-shadow;
      }
    }
  }

  .plus-minus-buttons-wrapper {
    display: flex;
    margin-left: 36px;
    margin-top: 24px;
    .button-box {
      display: flex;
      .button {
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $location-rack-wrapper-border;
        border-radius: 7px;
        &:hover {
          border: 1.5px solid $location-rack-wrapper-border;
          box-shadow: 0px 3px 10px $location-rack-wrapper-box-shadow-hover;
        }
        &:active {
          border: 1px solid $location-rack-btn-border-active;
          box-shadow: 0px 3px 10px $location-rack-wrapper-box-shadow-active;
        }
      }
      .plus {
        margin: 0 8px;
      }
    }
    .button-box:first-child {
      margin-right: 40px;
    }
  }
}
.rack-info-popover{
  margin-top: 12px !important;
  .ant-popover-arrow{
    bottom: 8px !important;
  }
}

.ds-none {
  display: none !important;
}
