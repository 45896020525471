@import 'assets/css/variables.scss';

.button-with-select-wrapper {
  height: 32px;
  width: 100px;
  position: relative;

  .button-with-select {
    height: 32px;
    width: 100px;
    border: 1px solid $btn-border;
    border-radius: 7px;
    overflow: hidden;
    position: relative;

    .button-wrapper {
      .ant-btn {
        height: 32px !important;
        background: $btn-bg-main-light;
        border: none;
        padding-left: 0;
        span {
          color: $btn-input-inside-value;
        }
      }
    }
    .btn-select-main-button {
      .ant-btn {
        border-radius: 0%;
        padding-right: 30px;

        &[disabled] {
          background-color: $btn-bg-disabled;
          span {
            color: $btn-df-text-inside;
          }
        }

        &:not([disabled]):hover {
          background: $btn-bg-disabled-hover;
          span {
            color: $btn-color-disabled-hover;
          }
        }
      }
    }

    .open-img-wrapper {
      width: 30px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      padding: 5px 0;
      background: $btn-bg-main-light;
      cursor: pointer;
      .open-img {
        height: 100%;
        width: 100%;
        border-left: 1px solid $btn-border;
        position: relative;
        svg {
          position: absolute;
          top: 8px;
          right: 10px;
        }
      }
    }
    .enabled-dropdown {
      background: $btn-bg-main;
      .open-img {
        border-color: $btn-bg-main !important;
      }
    }
    .default-dropdown {
      background: transparent;
    }
    .disabled-dropdown {
      background: $btn-bg-disabled;
      .open-img {
        border-color: $white !important;
      }
    }
  }
}

.important-button-with-select {
  .button-with-select {
    border-color: $orange;
    .btn-select-main-button {
      .ant-btn {
        span {
          color: $orange;
        }
      }
      .ant-btn:not([disabled]):hover{
        span{
          color: $orange;
        }
      }
    }
  }
}
.important-button-with-select .button-with-select .open-img-wrapper .open-img{
  border-color: $orange;
  svg{
    fill: $orange;
    path{
      stroke: $orange;
    }
  }
}

.disabled-button-with-select {
  .button-with-select {
    border: none;
    .open-img-wrapper .open-img{
      svg{
        fill: $white;
        path{
          stroke: $white;
        }
      }
    }
  }
}
.button-with-select-dropdown {
  display: none;
  min-width: 100px;
  width: auto;
  height: auto;
  position: absolute;
  top: 37px;
  right: 0;
  z-index: 10;
  border: 1px solid $btn-border-dropdown;
  box-shadow: 0px 5px 47px $btn-border-dropdown-box-shadow;
  border-radius: 10px;
  background: $btn-bg-dropdown;
  overflow: hidden;
  .button-wrapper {
    .ant-btn {
      background: transparent;
      border: none;
      border-radius: 0%;
      justify-content: flex-start;
      span {
        color: $blue;
      }
    }
    &:hover {
      .ant-btn {
        background: $btn-bg-dropdown-hover;
      }
    }
    &:active {
      .ant-btn {
        background: $blue;
        span {
          color: $btn-df-text-inside;
        }
      }
    }
  }
  .remove-btn {
    .ant-btn {
      &:hover {
        span {
          color: $error-text !important;
        }
      }
      span {
        color: $error-text;
      }
    }
  }
}
.active-button-with-select {
  .open-img-wrapper {
    background: $blue;
    .open-img {
      border-left: none !important;
      border-right: 1px solid $btn-border;
      transform: rotate(180deg);
    }
  }
  .button-with-select-dropdown {
    display: flex;
    flex-direction: column;
  }
}

.disabled-button-with-select {
  .button-with-select {
    border-color: $btn-bg-disabled;
    .open-img-wrapper {
      .open-img {
        border-color: $btn-border-dropdown;
        cursor: default;
      }
    }
  }
}
