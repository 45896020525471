@import 'assets/css/variables';

.order-info-container {
  .order-info-store-select {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: transparent;
    }
  }
  .text-area-block {
    .ant-input[disabled] {
      background: transparent;
    }
  }
  .second-line-inputs-wrapper {
    .input-container:first-child {
      margin-bottom: 20px;
    }
  }
  .input-container {
    height: 45px;
    .ant-input {
      height: 45px;
    }
  }
  .col-input {
    padding: 0 !important;
  }
  .inbound-date-picker {
    width: 100%;
    .ant-picker {
      width: 100%;
      height: 45px;
    }
  }
  .save-btn-with-dropdown {
    &:hover {
      .default-dropdown {
        .open-img {
          border-color: $blue !important;
          svg {
            fill: $blue !important;
            path {
              fill: $blue !important;
              stroke: $blue !important;
            }
          }
        }
      }
    }
    .open-img-wrapper:hover {
      .open-img {
        border-color: $white !important;
        svg {
          fill: $white !important;
          path {
            fill: $white !important;
            stroke: $white !important;
          }
        }
      }
    }
  }

  .save-btn-with-dropdown {
    border-radius: 8px;
    .button-with-select {
      border: none;
      .button-wrapper {
        .ant-btn {
          outline: 1px solid $btn-bg-main;
          outline-offset: -1px;
          border-radius: 8px;
          background: $btn-bg-main;
          span {
            color: $white;
          }
        }
      }
    }
    .button-with-select .open-img-wrapper {
      .open-img {
        border-left: 1px solid $outbound-btn-with-select-color;
        svg {
          fill: $outbound-btn-with-select-color;
          path {
            fill: $outbound-btn-with-select-color;
            stroke: $outbound-btn-with-select-color;
          }
        }
      }
    }
  }
  .active-button-with-select {
    .button-with-select {
      .open-img-wrapper {
        .open-img {
          border-right: 1px solid $white;
        }
      }
    }
  }
  .align-right {
    display: flex;
    justify-content: flex-end;
  }

  .save-btn-with-dropdown .button-with-select .button-wrapper .ant-btn[disabled] {
    background: $btn-bg-disabled !important;
    outline-color: $btn-bg-disabled !important;
  }

  .terminate-reason-textarea {
    .ant-input {
      height: 90px;
    }
  }

  .error-date-picker {
    .ant-picker {
      border-color: $error-border;
    }
  }

  .expected-message-error {
    margin: 0;
    margin-top: 5px;
    color: $error-text;
  }

  .textarea-notes {
    height: 180px;
  }
}

@media (max-width: 1550px) {
  .order-info-container {
    .inbound-date-picker {
      .ant-picker {
        height: 36px;
      }
    }
  }
}
