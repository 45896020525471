@import  'assets/css/colorConstants';

.trash-icon-svg {
  &:hover {
    svg {
      box-shadow: 0px 3px 15px $box-shadow-img-trash;
    }
    .trash-border {
      stroke: $dark-border;
    }
  }
  &:active {
    svg {
      box-shadow: 0px 3px 15px $box-shadow-img-trash;
    }
    .trash-border {
      stroke: $error-border;
    }
  }
}
.disabled-trash-icon {
  cursor: not-allowed !important;
  box-shadow: none !important;
  .trash-border {
    stroke: $border-card-light-border !important;
  }
}

@media (max-width: 1550px) {
  .trash-icon-svg {
    width: 32px;
    height: 32px;
  }
}
