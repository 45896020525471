@import 'assets/css/variables';

.root-report-order-inbound {
  height: 100%;
  overflow: scroll;
  text-align: center;
  padding: 0 24px 0 32px;

  .header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .time-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  .header-inbound {
    .row-header-info {
      margin-bottom: 15px;
    }

    .row-header-note {
      margin-bottom: 15px;
    }
    
    div {
      text-align: left;
    }
  }

  .table-block {
    margin-bottom: 16px;

    .table-header {
      margin-bottom: 16px;
     .table-item-info {
      text-align: left;
      display: flex;

      .item-info {
        margin-right: 15px;
        span {
          font-weight: 600;
        }
      }
     }
   }

   .header-special-table {
      border: 1px solid #abbdc5;
      border-bottom: none;
      margin-bottom: -2px;
      text-align: left;
      padding: 1px 6px 3px;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
   }
  }

  .table-report-full-table {
    overflow: scroll;
    .ant-table {
      font-size: 10px;
      border: 1px solid #acbcc6;

      .ant-table-thead > tr > th {
        padding: 8.5px 8px;
        background: transparent;
        &:first-child {
          font-weight: 400;
        }
      }
      .ant-table-container > .ant-table-content > table > tbody > tr > td {
        border-right: 1px solid #acbcc6;
        &:last-child {
          border-right: none;
        }
      }

      .ant-table-container > .ant-table-content > table > thead > tr > th {
        border-right: 1px solid #acbcc6;
        &:last-child {
          border-right: none;
        }
      }
      .ant-table-tbody > tr > td {
        border: none;
        border-top: 1px solid #acbcc6;
        padding: 8.5px 8px;
      }
    }

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .table-report-full-table .ant-table {
    margin-bottom: 7px;

    .ant-table-thead > tr > th {
      font-weight: 300;
      padding: 1.5px 5px;
      background: #eff3f5;
    }

    .ant-table-tbody > tr > td {
      border: none;
      border-top: 1px solid #acbcc6;
      padding: 4px 5px;
      font-weight: 400;
      letter-spacing: -0.3px;
    }
  }

  

  .ant-table tfoot > tr > td {
    padding: 4px 5px;
    border-bottom: none;
    border-top: 1px solid #acbbc6;
    border-right: 1px solid #acbbc6 !important;
    background: #eff3f5;

    &:last-child {
      border-right: 1px solid transparent !important;
    }
  }

  .count-block-from-table {
    font-size: 10px;
    font-weight: 300;
    margin-bottom: 25px;
  }
}
