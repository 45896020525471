@import 'assets/css/variables';

.document-wrapper {
  background: $upload-file-card-bg;
  border-radius: 10px;
  padding: 14px 32px;
  margin-top: 16px;
  &:hover {
    box-shadow: 0px 22px 61px -2px rgba(5, 60, 72, 0.00722222), 0px 14.2593px 35.7245px -2px rgba(5, 60, 72, 0.0117778),
      0px 8.47407px 19.4296px -2px rgba(5, 60, 72, 0.015), 0px 4.4px 9.9125px -2px rgba(5, 60, 72, 0.0182222),
      0px 1.79259px 4.97037px -2px rgba(5, 60, 72, 0.0227778), 0px 0.407407px 2.40046px -2px rgba(5, 60, 72, 0.03);
  }

  .doc-name-wrap {
    .doc-name {
      margin: 0;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      color: $dark-text;
    }
    .doc-size,
    .date-of-doc {
      font-family: 'Poppins';
      font-weight: 300;
      font-size: 12px;
      color: $gray-text;
    }
    .doc-size{
      margin-left: 8px;
    }
    .lock-icon{
      margin-left: 8px;
      cursor: pointer;
    }
  }
  .doc-btns-wrap {
    display: flex;
    justify-content: flex-end;
    .download-doc-btn {
      cursor: pointer;
    }
    .remove-doc-btn {
      margin-left: 10px;
    }
  }
}

.removing-doc-confirm-block {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $border-light-gray;
  padding: 22px 0;
  box-shadow: 0px 18px 100px rgba(47, 52, 53, 0.03), 0px 3.6px 17px rgba(113, 125, 128, 0.02);
  border-radius: 10px;
  .delete-doc-text{
    margin-right: 12px;
  }
  .yes-btn,
  .no-btn {
    width: 60px;
  }
  .no-btn {
    margin-left: 10px;
    .ant-btn {
      background: $white;
      border-color: $dark-border;
      span {
        color: $dark-text;
      }
      &:hover {
        span {
          color: $dark-text;
        }
      }
    }
  }
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: $dark-text;
    max-width: 250px;
  }
}

@media (max-width: 1550px) {
  .document-wrapper {
    .download-doc-btn {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}
