@import 'assets/css/variables';

.out-order-info-container {
  .outbound-info-row {
    margin-top: 26px;
  }
  .ds-flex {
    display: flex;
  }
  .dispatch-button {
    height: 32px;
    margin-left: 16px !important;
  }
  .readonly-field {
    .ant-input {
      background: $readonly-field !important;
    }
    .ant-select.ant-select-in-form-item {
      background: $readonly-field !important;
    }
  }
  .margin-col {
    margin-bottom: 24px;
  }
  .outbound-save-btn {
    float: right;
    .button-with-select {
      border: none;
      .button-wrapper {
        .ant-btn {
          background: $outbound-btn-with-select-bg;
          border-radius: 7px;
          &:hover {
            border: 1px solid $btn-border;
          }
          span {
            color: $outbound-btn-with-select-color;
          }
        }
        .ant-btn[disabled] {
          background: $outbound-btn-with-select-disabled-color;
          &:hover {
            border-color: $outbound-btn-with-select-disabled-color;
          }
          span {
            color: $outbound-btn-with-select-color;
          }
        }
      }
    }
    .button-with-select .open-img-wrapper {
      .open-img {
        border-left: 1px solid $outbound-btn-with-select-color;
        svg {
          fill: $outbound-btn-with-select-color;
          path {
            fill: $outbound-btn-with-select-color;
            stroke: $outbound-btn-with-select-color;
          }
        }
      }
    }
    .button-in-dropdown-btn {
      color: $blue;
      padding: 6px 14px;
      min-width: 150px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Poppins';
      &:hover {
        background: $light-gray;
      }
    }
  }
  .outbound-save-btn {
    &:hover {
      .default-dropdown {
        .open-img {
          border-color: $blue;
          svg {
            fill: $blue;
            path {
              fill: $blue;
              stroke: $blue;
            }
          }
        }
      }
    }
  }
  .terminate-row-inputs {
    margin-top: 26px;
  }
  .active-button-with-select .open-img-wrapper .open-img {
    border-left: 1px solid $outbound-btn-with-select-open-img !important;
  }
  .small-input {
    .placeholder {
      left: 10px;
    }
    .ant-input {
      padding: 13px 10px;
    }
  }

  .textarea-notes {
    height: 190px;
  }

}

@media (max-width: 1550px) {
  .out-order-info-container {
    .text-area-block {
      .ant-input {
        height: 100px;
      }
    }
    .dispatch-button {
      height: 32px !important;
      .ant-btn {
        height: 32px !important;
      }
    }
    .terminate-row-inputs {
      margin-top: 32px;
    }
  }
}

.terminate-order-modal {
  height: auto;
  .ant-modal-content {
    height: auto;
    .ant-modal-body {
      height: auto;
      padding: 0 40px;
      .dialog-body {
        height: auto;
        .buttons-wrapper {
          display: flex;
          justify-content: flex-end;
          margin-top: 40px;
          .terminate-button {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

