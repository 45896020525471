@import 'assets/css/variables';

.locations-main-wrapper-container {
  .wrapper-body {
    padding: 0;
    overflow: auto;
    position: relative;
    height: calc(100% - 65px);
    &::-webkit-scrollbar {
      width: 16px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: $view-locations-scroll-trakc-bg;
      border-left: 2px solid $view-locations-scroll-trakc-border;
      width: 17px;
      padding: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $view-locations-scroll-trakc-thumb-bg;
      border: 2px solid $view-locations-scroll-trakc-thumb-border;
    }
    .racks-container {
      padding: 72px 48px 12px 48px;
    }
  }
}
.locations-right-info-rack-card {
  .ant-collapse-item:last-child > .ant-collapse-content {
    padding: 24px 0 40px 0;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
}
.locations-bins-right-card {
  .ant-collapse-item:last-child > .ant-collapse-content {
    padding: 0 0 20px 0;
  }
}
.delete-rack-button-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 1400px) {
  .locations-main-wrapper-container {
    .wrapper-body {
      .racks-container-adaptive {
        padding-top: 102px;
      }
    }
  }
}
