@import 'assets/css/variables';

.item-uom-block {
  background: $item-uom-block-bg;
  border-radius: 15px;
  padding: 32px 24px;
  margin-bottom: 24px;
  box-shadow: $item-uom-block-box-shadow;
  .input-container {
    height: 45px;
    .ant-input {
      height: 45px;
    }
    .passive-label {
      top: 11px;
    }
  }
  .item-uom-title {
    display: inline-block;
    margin-right: 46px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: $item-uom-df-color;
    text-decoration: underline;
    text-decoration-color: $item-uom-title-decoration-color;
  }
  .uom-inputs-container {
    margin-top: 24px;
  }
  .item-uom-contains-block {
    margin-top: 32px;
    p {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 16px;
      color: $item-uom-df-color;
      margin: 0;
    }
    .input-container {
      margin: 0 26px;
    }
  }

  .error-input{
    .ant-input{
      border-color: $input-error-color;
    }
  }

  .copy-uom-internal_id-block {
    display: inline-block;
    margin: 0 5px 0 24px;
    cursor: pointer;
    &:hover {
      .copy-icon-container {
        border: 1px solid $item-uom-internal-block-copy-hover;
      }
    }
    &:active {
      .copy-icon-container {
        border: 1px solid $item-uom-df-color;
      }
    }
    .copy-icon-container {
      border: 1px solid $item-uom-copy-icon-container-border;
      border-radius: 7px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .item-uom-internal_id {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: $item-uom-df-color;
    opacity: 0.8;
  }
  .item-related-block{
    position: relative;
    display: inline-block;
  }
}
