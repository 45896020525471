.cell-info-checkboxx-block {
  padding: 20px 0;
}
.cell-info-save-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .cell-info-save-btn {
    margin-top: 40px;
    width: 120px;
  }

  .ant-form-item-control {
    flex-direction: row-reverse;
  }
}
.cell-info-form-block {
  .input-container {
    height: 45px;
    .active-label{
      left: 12px;
    }
    .passive-label{
      top: 13px;
      left: 6px;
    }
    .ant-input {
      height: 45px;
    }
  }
  .text-area-block{
    .ant-input[disabled]{
      background: transparent;
    }
  }

}
