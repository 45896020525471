@import "./colorConstants.scss";
@import "./variables";

.login-main-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../images/loginBackground.jpg');
  background-size: cover;
}
.left-side {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding-left: 7%;

  svg {
    width: 55%;
  }
}
.right-side {
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  background-image: url('../images/bluderLoginBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 7%;
  svg {
    width: 55%;
  }
}
.login-block {
  background: $body-bg-title-color;
  padding: 40px 66px 56px 66px;
  box-shadow: 0 12px 70px $box-shadow-login-block;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  height: auto;
  z-index: 2;
  position: relative;
}
.logo-container{
  img{
    width: 100%;
    height: 100%;
  }
}
.links-container {
  display: flex;
  margin: 40px 0 56px 0;
  p {
    cursor: pointer;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    color: $gray-text;
    margin: 0;
  }
  .active {
    color: $link-color-active;
  }
}
.horizontal-line {
  width: 2px;
  height: 32px;
  background: $bg-df-line;
  margin: 0 24px;
}

.display-none {
  display: none;
}

@media (max-width: 1550px) {
  .login-block {
    width: 380px;
    height: auto;
    padding: 32px 40px;
    input,
    button {
      height: 37px;
    }
  }
  .links-container {
    margin: 32px 0 8px 0;
  }
}
