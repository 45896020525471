@import 'assets/css/variables';

.button-wrapper {
  height: 100%;
}

.button-wrapper .ant-btn {
  background: $btn-bg-main;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: none;
  &:focus {
    background: $btn-bg-main;
  }
}
.button-wrapper .ant-btn:empty {
  content: none;
  visibility: visible;
  width: 100%;
}

.button-wrapper .ant-btn span {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: $btn-df-text-inside;
}
.button-wrapper .ant-btn[disabled] {
  background-color: $btn-bg-disabled;
}

.button-wrapper .ant-btn:not([disabled]):hover {
  background: $btn-bg-disabled-hover;
  span {
    color: $btn-color-disabled-hover;
  }
}

.important-button-wrapper {
  .ant-btn {
    background: $btn-important-bg-orange;
    border-color: transparent;
  }
  .ant-btn:not([disabled]):hover {
    border: 1px solid $btn-important-bg-orange;
    span {
      color: $orange;
    }
  }
  .ant-btn[disabled] {
    background: $btn-bg-disabled;
  }
  .ant-btn:focus {
    background: $btn-important-bg-orange;
    border-color: transparent;
  }
}

@media (max-width: 1550px) {
  .button-wrapper {
    height: 34px !important;
    .ant-btn {
      height: 34px !important;
      padding: 9.5px 15px;
    }
  }
}
