@import 'assets/css/variables';

.external-ids-block-wrapper {
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  margin-left: 0 !important;
  .input-container {
    height: 45px;
    .ant-input {
      height: 45px;
    }
    .passive-label {
      top: 11px;
    }
  }
  .mar-bot{
    margin-bottom: 30px;
  }
  .text-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-save-buttons-wrapper{
    display: flex;
    justify-content: flex-end;
    position: relative;
    .outbound-error-message-circle{
      top: 11px;
      left: -4px;
    }
  }
  .add-ext-id-btn{
    margin-right: 16px;
    .ant-btn{
      background: $white;
      border-color: $blue;
      span{
        color: $blue;
      }
    }
  }
  .error-message{
    position: absolute;
    color: $input-error-color;
  }
  .error-input{
    .ant-input{
      border-color: $input-error-color;
    }
  }
  .pos-relative{
    position: relative;
  }
 
}

.error-message{
  color:  $error-text;
}

.save-ext-id-popover{
  .ant-popover-content {
    right: -19px !important;
  }
}

@media (max-width: 1550px) {
  .disabled-item-delete-block {
    width: 36px;
    height: 36px;
    svg{
      width: 100%;
      height: 100%;

    }
  }
}
