@import 'assets/css/variables';

.popover-container {
  .ant-popover-inner {
    border-radius: 5px !important;
    border: 1px solid $popover-df-border;
    box-shadow: none !important;
    .ant-popover-inner-content {
      padding: 8px 16px;
      min-width: 110px;
      border-radius: 5px;
      background: $white;
      box-shadow: $popover-df-box-shadow;
      .error-message-text {
        color: $popover-error-message;
        margin: 0;
      }
    }
  }
}

.popover-circle-block{
  margin-right: 5px;
  border: 1px solid $border-light-gray-active;
  display: inline-block;
  width: 16px;
  background: $white;
  color: $dark-text;
  min-width: 16px;
  height: 16px;
  text-align: center;
  font-size: 10px;
  border-radius: 50%;
  cursor: pointer;
}