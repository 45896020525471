@import 'assets/css/variables';

.right-wrapper-col {
  height: 100%;
  overflow: hidden;
  .right-wrapper-block {
    margin-left: 24px;
    .right-wrapper-header {
      width: 100%;
      height: 70px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      background: $right-wrapper-col-bg;
      box-shadow: $right-wrapper-col-box-shadow;
      border-radius: 15px;
      padding: 0 48px;
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 20px;
      color: $right-wrapper-col-color;
      svg {
        cursor: pointer;
      }
    }
    .right-wrapper-body {
      height: calc(100vh - 120px);
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-color: #3dc4ee #ffffff00;

      &::-webkit-scrollbar {
        width: 14px;
        scrollbar-color: #3dc4ee #ffffff00;
        scrollbar-width: thin;
      }
    }
  }
}
@media (max-width: 1550px) {
  .right-wrapper-block .right-wrapper-header {
    height: 56px !important;
  }
}
