.root-inventory-report-row {
  height: 100%;
  padding: 0 24px;
  overflow: scroll;
  text-align: center;
  .header {
    font-size: 14px;
    font-weight: 500;
  }

  .time-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
  }

  .time-block {
    margin-bottom: 30px;
  }

  .table-report-inventory,
  .table-report-inventory-full-table {
    margin-bottom: 15px;
    overflow: scroll;
    .ant-table {
      font-size: 10px;
      border: 1px solid #acbcc6;

      &::-webkit-scrollbar {
        display: none !important;
      }

      .ant-table-thead,
      .ant-table-container,
      .ant-table-content,
      .ant-table-tbody {
        &::-webkit-scrollbar {
          display: none !important;
        }
      }

      .ant-table-thead > tr > th {
        padding: 8.5px 8px;
        background: transparent;
        &:first-child {
          font-weight: 400;
        }
      }
      .ant-table-container > .ant-table-content > table > tbody > tr > td {
        border-right: 1px solid #acbcc6;
        &:last-child {
          border-right: none;
        }
      }

      .ant-table-container > .ant-table-content > table > thead > tr > th {
        border-right: 1px solid #acbcc6;
        &:last-child {
          border-right: none;
        }
      }
      .ant-table-tbody > tr > td {
        border: none;
        border-top: 1px solid #acbcc6;
        padding: 8.5px 8px;
      }
    }

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .table-report-inventory-full-table .ant-table {
    margin-bottom: 20px;

    .ant-table-thead > tr > th {
      font-weight: 300;
      padding: 1.5px 5px;
      background: #eff3f5;
    }

    .ant-table-tbody > tr > td {
      border: none;
      border-top: 1px solid #acbcc6;
      padding: 4px 5px;
      font-weight: 400;
      letter-spacing: -0.3px;
    }

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .count-block-from-table {
    font-size: 10px;
    font-weight: 300;
  }

  &::-webkit-scrollbar {
    display: none !important;
  }
}
