@import 'assets/css/variables';

.store-info-container {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: $store-info-df-color;
  }
  .custom-select-with-search-disabled {
    img {
      display: none;
    }
  }
  .col-padding {
    padding: 0 18px !important;
  }
}

@media (max-width: 1550px) {
  .store-info-container {
    .store-photo-field {
      .ant-upload {
        height: 90px !important;
      }
    }
  }
}
