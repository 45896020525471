@import 'assets/css/variables';

.warehouse-uom-block {
  display: contents;
  .mar-bot-7 {
    margin-bottom: 12px;
  }

  .popover-circle {
    margin-right: 5px;
    margin-top: 3px;
    border: 1px solid;
    display: inline-block;
    width: 16px;
    min-width: 16px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 50%;
    cursor: pointer;
    border-color: $location-uom-uint-carton-statuses-border-inbound;
    &.Inbound {
      border-color: $location-uom-uint-carton-statuses-border-inbound;
    }
    &.Outbound {
      border-color: $location-uom-uint-carton-statuses-border-outbound;
    }

    &.Allocated {
      border-color: $location-uom-uint-carton-statuses-border-allocated;
    }

    &.Processing {
      border-color: $location-uom-uint-carton-statuses-border-processing;
    }

    &.Reserved {
      border-color: $location-uom-uint-carton-statuses-border-reserved;
    }

    &.Picked {
      border-color: $location-uom-uint-carton-statuses-border-picked;
    }
  }
}

.warehouse-uom-statuses-popover {
  .ant-popover-content {
    right: 19px !important;
    top: 5px !important;
  }
}
