@import 'assets/css/variables';

.right-card-block {
  height: fit-content;
  margin-bottom: 24px;

  .ant-collapse {
    height: fit-content;
    border: none;
    border-radius: 15px;
  }
  .ant-collapse > .ant-collapse-item {
    width: 100%;
    border: none;
  }
  .ant-collapse .ant-collapse-item:last-child .ant-collapse-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: $right-card-collapse-header-bg;
    height: 69px;
    display: flex;
    align-items: center;
    padding: 0 48px;
    border: none;
    border-bottom: 2px solid $right-card-collapse-border;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    color: $right-card-collapse-color;
    position: relative;
  }
  .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    right: 40px;
    top: 24px;
    margin: 0;
    transform: rotate(180deg);
  }
  .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg {
    width: 14px;
    height: 24px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 16px;
    border: none;
  }
  .right-card-body {
    height: fit-content;
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: transparent !important;
      color: $right-card-collapse-color !important;
    }
    .ant-select-disabled {
      .ant-select-arrow {
        display: none;
      }
    }
    .input-container {
      .ant-input {
        height: 45px;
      }
      .passive-label{
        top: 12px;
      }
      .ant-input[disabled] {
        background: transparent;
        color: $right-card-collapse-color !important;
        border-color: $right-card-collapse-border;
      }
    }
    .text-area-block{
      .ant-input[disabled]{
        border-color: $right-card-collapse-border;
      }
    }
  }
  .right-card-header {
    display: flex;
    align-items: center;
    .header-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      .edit-mode-button,
      .close-mode-button {
        background: transparent;
        border: none;
        margin-right: 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        height: 40px;
        width: 40px;
        cursor: pointer;
      }
      .close-mode-button {
        border: 1px solid $right-card-mode-close-btn-border;
      }
      .edit-mode-button {
        border: 1px solid $right-card-mode-edit-btn-border;
      }
    }
  }
}

.closed-card-block {
  .ant-collapse .ant-collapse-item:last-child .ant-collapse-header {
    border-radius: 15px;
  }
}

@media (max-width: 1550px) {
  .right-card-block .ant-collapse .ant-collapse-item:last-child .ant-collapse-header {
    height: 56px;
  }
  .right-card-block .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    top: 17px;
  }
  .right-card-block .ant-collapse-item:last-child > .ant-collapse-content {
    padding: 17px 4px;
  }
  .right-card-block {
    .right-card-header {
      .header-icon {
        .edit-mode-button,
        .close-mode-button {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
