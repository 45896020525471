@import 'assets/css/_variables.scss';
@import './utils.scss';
@import 'assets/css/colorConstants';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  height: 100vh;
  #root{
    height: 100%;
  }
  div{
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $blue;
      border: 3px solid $blue;
      border-radius: 5px;
    }
  }
}

.App {
  display: flex;
  background-color: $light-gray-background;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}
