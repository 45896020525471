@import "./colorConstants.scss";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// --- --- Globals --- ---

//Main
$font-size-base: 14px !default; 
$body-bg: $light-gray-background !default;
$body-bg-title-color: $white !default;
$body-text-color: $dark-text !default;
$body-main-action-color: $blue !default;

// assets/css
$stores-dispaly-data-table-bg: $white;
$stores-dispaly-data-table-color: $white;
$stores-dispaly-data-table-td-bg: $dark-color-hight;

$users-confirm-df-bg: $confirm-alert-overlay-color;
$users-confirm-border: $border-light-gray;
$users-confirm-btn-group-bg: $blue-legenda;

//Alert 
$color-confirm-alert-body: $dark-light;

$bg-selected-component: $light-gray; 
$bg-df-hover-color: $bg-hover-white-light;
$bg-ant-select-color: $blue;

//bx-shadow
$box-shadow-df: $box-shadow-dark;

// Link
$link-color: $dark-text !default;
$link-color-active: $dark-text !default;
$link-color-df: $gray-text;

$link-hover-color: $dark-color-hight !default;
$link-border-radius: 5px; 

//Border
$border-color-df: $white-light !default;
$border-radius-base: 15px; 
$border-color-light: $border-light-gray;
$error-border: $error-border;

// --- Input ---
// input bg
$input-bg-active-label: $white;

// input border 
$input-border: $border-light-gray !default;
$input-border-affix-focus: $border-light-gray-focus; 

// input text
$input-label-fs: 12px;
$input-fs-l: 16px;
$input-fs-m: 14px;
$input-fs-s: 12px;

$input-label-color: $gray-text !default;
$input-placeholder-color: $gray-text !default;
$input-color: $dark-text !default;
$input-error-color: $error-text;
$input-color-active-label: $dark-color-light !default;
$input-container-span: $gray-text !default;


// --- Checkbox ---
$checkbox-bg-inner: $blue;
$checkbox-bg-disabled-bg: $border-light-gray;

$checkbox-bg-inner-after-color: $white;
$checkbox-bg-inner-after-disabled-color: $blue;

// chk border
$checkbox-border-color: $blue;
$checkbox-border-color-after: $blue;

// chk text
$checkbox-wrapper-color : $dark-text;


// --- Button ---
$btn-transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);

// btn bg
$btn-bg-dropdown: $white !default;
$btn-bg-main: $blue !default;
$btn-bg-disabled: $secondary !default;
$btn-bg-main-light: $white !default;
$btn-important-bg-orange: $orange !default;

// btn border
$btn-border: $blue !default;
$btn-border-dropdown: $border-light-gray !default;
$btn-df-border-radius-l: 15px !default;
$btn-df-border-radius-m: 7px !default;
$btn-border-radius-s: 6px !default;
$btn-border-dropdown-box-shadow: $box-shadow-btn-dropdown;

// btn hover
$btn-hover-border: $blue !default;
$btn-bg-hover: $white !default;
$btn-disabled-hover: $white-light !default;
$btn-bg-dropdown-hover: $light-gray !default;
$btn-bg-disabled-hover: $white !default;
$btn-color-disabled-hover: $blue !default;

// btn text
$btn-input-inside-value: $blue !default;
$btn-disabled-text: $gray-text !default;
$btn-alert-button-group-color: $dark-light !default;
$btn-df-text-inside: $white !default;
$btn-disabled-text-light-gray: $light-gray;

// --- Copiend message ---
$copied-block-bg: $light-gray;
$copied-block-color: $dark-text;

// --- Popover ---
$popover-df-border: $border-light-gray !default;
$popover-df-box-shadow: 0px 1px 25px $box-shadow-dark-light;
$popover-error-message: $error-text;

// --- Radio btn ---
$radio-btn-border: $blue;
$radio-btn-color: $dark-text;

//--- Input fields ---
$readonly-field: $bg-readonly;

// --- Search input ---
$search-input-border: $border-light-gray;
$search-input-color: $dark-text;
$search-input-fill-svg: $blue;


// --- Select ---
$select-border: $border-light-gray !default;
$select-label-bg-color: $white;

// select color
$select-color: $dark-light !default;
$select-wrapper-span-color: $gray-text;
$select-color-error: $error-text;

// select label
$select-active-label-color: $dark-light-select-active;

// --- CustomTable/CommonTable ---
// tb scrollbar
$common-table-scrollbar-color: $border-light-gray;
$common-table-scrollbar-bg: $white;
$common-table-scrollbar-bg-thumb: $blue;
$common-table-scrollbar-track: $white;
$common-table-scrollbar-track-border: $border-light-gray;

// tb cell
$common-table-cell-bg: $white;
$common-table-cell-border: $white;
$common-table-cell-border-child: $light-gray;

// tb hover cell
$common-table-cell-border-hover: $table-gray-border;
$common-table-cell-box-shadow-hover: 0px 22px 61px -2px rgba(5, 60, 72, 0.00722222),
0px 1.79259px 4.97037px -2px rgba(5, 60, 72, 0.0227778),
0px 0.407407px 2.40046px -2px rgba(5, 60, 72, 0.03); ;

// tb row 
$common-table-row-border-active: $blue;
$common-table-row-bg-active: $blue;

// tb row hover 
$common-table-row-border-hover: $blue;

// tb tbody-tr 
$common-table-tbody-tr-bg: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);

// --- CustomTable/tableSearch ---

$table-search-group-bg: $border-light-gray;
$table-search-group-fill-svg-df: $blue;
$table-search-group-df-color: $dark-text;


// --- TextArea ---
$tx-area-border-input: $border-light-gray;

// tx-area color
$tx-area-df-color: $dark-text;
$tx-area-span-color: $gray-text;
$tx-area-span-error-color: $error-text;
$tx-area-active-label-bg-color: $white;
$tx-area-active-label-color: rgba(10, 72, 91, 0.6);

// --- Modal Window ---
$modal-window-border-color: $border-light-gray;

// --- Select with search ---
$select-search-border-color: $border-light-gray;
$select-search-df-color: $dark-text;

$select-search-wrapper-span-color: $gray-text; 
$select-search-wrapper-span-error-color: $error-text;

$select-search-active-label-bg: $white;
$select-search-active-label-color: rgba(10, 72, 91, 0.6);

// --- Upload Image ---
$upload-img-border-picture-card: $border-light-gray;
$upload-img-picture-card-color-btn: $gray-bin;

// --- Upload File ---
$upload-file-border: $border-light-gray;
$upload-file-card-bg: linear-gradient(110.69deg, #F9FBFC 0%, #F6F9FA 100%, #F5F9FA 100%);;

// --- Pagination ---
$pagination-df-color: $gray-text;
$pagination-ant-pagination-color: $dark-text;
$pagination-pagination-li-color: $gray-light;
$pagination-pagination-item-color: $gray-text;

$pagination-select-border-btn: $border-light-gray;
$pagination-select-btn-color: $gray-text;
$pagination-select-btn-active-bg: $blue;
$pagination-select-btn-active-bg-a: $white;
$pagination-select-border-color: $gray-text;

$pagination-select-border: $border-card-light-border;
$pagination-select-color: $white;
$pagination-select-bg: $blue;


// --- --- Layout --- ---


// --- loading ---
$loader-wrapper-bg: $popover-wrapper;
$loader-wrapper-lds-ring: $white;
$loader-wrapper-lds-ring-div-border: $blue;

// --- Main wrapper ---
$wrapper-main-bg: $white;
$wrapper-main-df-border: $border-light-gray;
$wrapper-main-box-shadow: 0px 3px 5px $box-shadow-wrapper-block-light;
$wrapper-main-block-box-shadow: $box-shadow-wrapper-block;


$wrapper-main-icon-color: $blue;
$wrapper-main-color: $dark-text;

// --- Layout ---
$layout-main-bg: $light-gray-background;

// --- Right Card ---
$right-card-collapse-header-bg: $white;
$right-card-collapse-border: $border-light-gray;
$right-card-collapse-color: $dark-text;

$right-card-mode-close-btn-border: $border-card-light-border;
$right-card-mode-edit-btn-border: $blue;

// --- Right wrapper col---
$right-wrapper-col-bg: $white; 
$right-wrapper-col-box-shadow: 0px 5px 25px rgba(121, 155, 155, 0.1); 
$right-wrapper-col-color: $dark-text; 

// --- Sidebar --- 
$sidebar-wrapper-bg: $white;
$sidebar-wrapper-bg-avatar-block: $gray-avatar-df;
$sidebar-wrapper-box-shadow: 5px 0px 25px rgba(121, 155, 155, 0.1);

$sidebar-wrapper-logout-border: $dark-border;
$sidebar-wrapper-border-item: $border-light-gray;

$sidebar-wrapper-items-color: $gray-text;
$sidebar-wrapper-color: $dark-text;
$sidebar-wrapper-svg-fill-color: $blue;

// sidebar antd
$sidebar-antd-bg-content-inner: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);

// --- Location --- 
$location-border-df: $border-light-gray;
$location-loc-header-bg: linear-gradient(290.69deg, #f0f5f7 0%, #e7f0f2 100%, #f2f9fb 100%);
$location-btn-bg: $secondary;
$location-btn-bg-disabled: $secondary;

$location-title-block-bg: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);
$location-title-block-color: $dark-text;
$location-title-block-border: $border-light-gray;
$location-title-block-floor-bg: $border-card-light-border;

$location-inventory-loc-available-border: $blue;
$location-inventory-loc-unavailable-border: $border-card-light-border;

// --- Location chooser modal ---
$chooser-loc-modal-color: $dark-text;
$chooser-loc-modal-border-container-bin: $border-light-gray;

$chooser-loc-modal-error-message-circle-border: $gray-border;
$chooser-loc-modal-error-message-circle-bg: $white;
$chooser-loc-modal-error-message-circle-color: $error-text;

// --- location measurenebt unit || location-uom ---
$location-uom-uint-move-cancel-btn-border: $dark-border;
$location-uom-uint-move-cancel-btn-bg: $white;
$location-uom-uint-move-cancel-btn-color: $dark-text;

$location-uom-uint-carton-statuses-border-inbound: $df-inbound-color;
$location-uom-uint-carton-statuses-border-outbound: $df-outbound-color;
$location-uom-uint-carton-statuses-border-allocated: $df-allocated-color;
$location-uom-uint-carton-statuses-border-processing: $df-processing-color;
$location-uom-uint-carton-statuses-border-reserved: $df-reserved-color;
$location-uom-uint-carton-statuses-border-picked: $df-picked-color;

// --- items/information ---
$item-information-inventory-color: $dark-text;
$item-information-inventory-col-border: $border-light-gray;


// items/uintOfMeasurement || uom
$item-uom-block-bg: $white;
$item-uom-block-box-shadow: 0px 5px 30px rgba(202, 202, 202, 0.1);
$item-uom-copy-icon-container-border: $border-card-light-border;

// color
$item-uom-df-color: $dark-text;
$item-uom-internal-block-copy-hover: $gray-text;
$item-uom-title-decoration-color: $blue;

// --- Locations/Bins ---
$locations-bins-no-bins-color: $border-card-light-border;

// Locations/Bin
$locations-bin-df-bg: $white;
$locations-bin-df-color: $dark-text;
$locations-bin-df-border: $border-light-gray;
$locations-bin-container-bg-header:linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);

$location-bin-delet-alert-box-shadow: 0px 18px 100px rgba(47, 52, 53, 0.03), 0px 3.6px 17px rgba(113, 125, 128, 0.02);

$location-bin-ant-btn-bg: $white; 
$location-bin-ant-btn-border: $dark-border;
$location-bin-ant-btn-color: $dark-text; 
$location-bin-ant-btn-box-shadow-hover: 0px 3px 15px $box-shadow-img-trash;
$location-bin-ant-btn-box-shadow: $box-shadow-bin-active;

// --- Locations/LocationLegenda ---
$location-legenda-df-bg: $white;
$location-legenda-df-color: $dark-text;

$location-legenda-block1-bg: $blue-legenda;
$location-legenda-block2-bg: $error-text;
$location-legenda-block3-bg: $gray-bin;
$location-legenda-block4-bg: $yellow-legenda;

// Locations/newRackModal
$location-new-rack-border: $border-light-gray;
$location-new-rack-value-title: $dark-text;

// Locations/Rack
$location-rack-wrapper-bg: $white;
$location-rack-wrapper-border: $border-light-gray;
$location-rack-btn-border-active: $border-light-gray-active;

$location-rack-wrapper-box-shadow: 0px 18px 100px hsla(190, 6%, 20%, 0.03), 0px 3.6px 17px rgba(113, 125, 128, 0.02);
$location-rack-wrapper-box-shadow-active: $box-shadow-img-trash;
$location-rack-wrapper-box-shadow-hover: $box-shadow-rack-hover;

$location-rack-scrollbar-thumb-bg: $blue;
$location-rack-scrollbar-thumb-box-shadow: 0px 4px 10px rgba(138, 158, 160, 0.1);

$location-bin-rack-title-bg-hover: $light-gray-active;
$location-bin-rack-title-bg-active: $light-gray-hover;
$location-bin-rack-selected-title-bg: $light-gray;

$location-bin-rack-cell-bg: $gray-bin;
$location-bin-rack-cell-items-circle-border: $gray-bin;
$location-bin-rack-cell-items-circle-border-hover: $blue-legenda;
$location-bin-rack-cell-items-circle-bg: $white;
$location-bin-rack-cell-items-circle-color: $dark-text;

$locations-bin-rack-cell-noborder-hover: $light-gray-hover;
$locations-bin-rack-cell-noborder-active: $light-gray-selected;
$locations-bin-rack-cell-noborder-selected: $light-gray-selected;

$locations-bin-rack-not-available-bg: $error-bg;
$locations-bin-rack-not-available-box-shadow: $error-box-shadow;

$locations-bin-rack-ocuped-bg: $blue-legenda;
$locations-bin-rack-selected: $blue-box-shadow;
$locations-bin-rack-available-bg: $gray-bin;
$locations-bin-rack-available-bg-box-shadow: $box-shadow-white-light-color;

// $locations-bin-rack

// Locations/RackInfo
$location-rack-info-block-border: $border-light-gray;
$location-rack-info-readonly-field-bg: $light-gray-background;
$location-rack-info-readonly-field-border: $border-light-gray;

// --- Orders/ItemWithUOM ---
$orders-item-uom-container-border: $bg-gray;
$orders-item-uom-item-header-bg: $bg-gray;
$orders-item-uom-item-header-color: $dark-text;
$orders-item-uom-titles-bg: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);

// Orders/MeasurementUint
$orders-measurement-df-color: $dark-text;
$orders-measurement-ant-input-border-disabled: $border-light-gray-disabled;
$orders-measurement-ant-input-bg-disabled: $white-light;

$orders-measurement-wrapper-ant-select-border-transit: $blue-legenda;
$orders-measurement-wrapper-ant-select-border-success: $success;
$orders-measurement-wrapper-ant-select-border-damaged: $error-bg;
$orders-measurement-wrapper-ant-select-border-lost: $orange;

// --- Orders/OrderInformationBlock ---
$orders-information-receive-ant-btn-bg: $orange;
$orders-information-receive-ant-btn-color: $orange;
$orders-information-receive-ant-btn-disabled-border: $orange; 
$orders-information-receive-ant-btn-disabled-bg: $secondary; 

// Orders/unsavedDataModal
$orders-unsaved-data-df-color: $dark-text;
$orders-unsaved-data-btn-cancel-bg: $white;
$orders-unsaved-data-btn-cancel-border: $dark-border;
$orders-unsaved-data-btn-cancel-color: $dark-text;

// --- Outbound ---
$outbound-btn-with-select-border: $gray-text;
$outbound-btn-with-select-bg: $blue;
$outbound-btn-with-select-color: $white;
$outbound-btn-with-select-open-img: $blue;

$outbound-btn-with-select-disabled-color: $gray-text;

// --- Stores/RelatedUser ---
$store-related-card-df-color: $dark-text;
$store-related-card-df-item-bg: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);

// Stores/StoreInfoBlock
$store-info-df-color: $dark-text;


// --- --- View ---- ---- 

// --- Inbound ---
$view-inbound-header-btn-bg: $white;
$view-inbound-header-btn-border: $blue;
$view-inbound-header-btn-color: $blue;
$view-inbound-no-data-color: $gray-text;

// --- Inventory ---
$view-inventory-collapse-content: $white-light-inventory;
$view-inventory-move-clicked-state-loc-bg: $white;
$view-inventory-box-shadow: $box-shadow-inventory;

// --- Items ---
$view-items-df-bg: $white-light-items;
$view-items-external-ids-block-color: $border-card-light-color;

// --- Locations ---
$view-locations-scroll-trakc-bg: $white;
$view-locations-scroll-trakc-thumb-bg: $blue;
$view-locations-scroll-trakc-thumb-border: $white;
$view-locations-scroll-trakc-border: $border-light-gray;

// --- Stores ---
$view-stores-no-data-df-color: $gray-text;

// --- 404 ---
$view-404-df-text: $text-404-df;
