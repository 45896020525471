@import 'assets/css/variables';

.show-copied-block {
  background: $copied-block-bg;
  border-radius: 5px;
  padding: 1px 7px;
  position: absolute;
  top: 6px;
  right: -75px;
  .copied-message {
    margin: 0;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 12px;
    color: $copied-block-color;
    opacity: 0.7;
  }
}
