@import './colorConstants.scss';

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.align-center{
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.position-relative {
  position: relative;
}

.text-sm-10 {
  font-size: 10px;
  font-weight: 400;
  color: $body-text-color;
}

.bold {
  font-weight: 600;
}