@import 'assets/css/variables';

.btn-input-inside_wrapper {
  .btn-input-inside_input-wrapper {
    display: flex;
    position: relative;
    .btn-input-inside_value-btn {
      position: absolute;
      right: 0;
      background: $btn-input-inside-value;
      border-radius: 7px;
      &:hover {
        border-radius: 7px;
      }
      .ant-btn {
        padding: 6px;
        border-radius: $btn-border-radius-s;
        span {
          color: $white;
        }
      }
    }

    .disabled-btn-inside {
      background: $btn-bg-disabled;

      .ant-btn {
        cursor: disabled;
        &:hover {
          background: $btn-bg-disabled;
        }
        span {
          color: $btn-disabled-text-light-gray;
        }
      }
    }

    .btn-input-inside_input {
      height: 32px !important;
      .ant-input {
        height: 32px !important;
      }
    }
  }
}
