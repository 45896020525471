@import 'assets/css/variables';

.inbound-items-wrapper {
  .buttons-layout {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    button {
      height: 40px;
    }
    .button-wrapper:last-child {
      margin-left: 14px;
    }
    .display-none {
      display: none;
    }
    .processing-order-text .ant-btn[disabled]{
      background: transparent;
      border: none;
      cursor: default;
      span{
        color: $dark-text;
      }
    }
  }
  .outbound-error-message-circle{
    right: 112px;
  }
}

.inbound-acknowledge-popover {
  .ant-popover-content {
    right: -19px !important;
  }
}
.acknowledge-error-text {
  color: $error-text;
  margin: 0;
}
