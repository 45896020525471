@import 'assets/css/variables';

.unit-wrapper {
  margin: 16px 0;
  justify-content: space-between;
  &:first-child,
  :last-child {
    margin: 0;
  }

  .ant-col {
    .custom-select-wrapper{
      max-width: 90px;
    }
    .ant-input{
      max-width: 70px;
    }
  }
  .col-btn {
    padding-right: 0;
  }

  .condition-select,
  .uom-select {
    .ant-select .ant-select-selection-item {
      font-size: 12px;
    }
  }
  .readonly-custom-select-container .ant-select-disabled.ant-select:not(.ant-select-customize-input){
    border: none;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }

  .received-text {
    width: 100px;
    text-align: center;
    font-weight: 500;
    color: $orders-measurement-df-color;
  }
  .contains-input, .measurement-input {
    .ant-input {
      padding: 0;
      text-align: center;
    }
    .ant-input[disabled] {
      border: 1px $orders-measurement-ant-input-border-disabled !important;
      background: $orders-measurement-ant-input-bg-disabled;
    }
  }
  .received-item {
    .lost,
    .in-transit,
    .ok,
    .damaged {
      border: none !important;
    }
    .ant-select {
      border: none;
    }
    .ant-select-arrow {
      display: none;
    }
    .ant-select-selector,
    .ant-input[disabled],
    .ant-select {
      border: none !important;
      cursor: default !important;
      background: transparent !important;
      color: $orders-measurement-df-color;
      span {
        color: $orders-measurement-df-color;
      }
    }
  }
  .unit-item,
  .unit-type,
  .unit-quantity,
  .unit-contains {
    .ant-select,
    .ant-input {
      height: 30px !important;
      font-weight: 300;
      font-size: 12px;
    }
    .in-transit {
      .ant-select {
        border: 1px solid $orders-measurement-wrapper-ant-select-border-transit;
      }
    }
    .ok {
      .ant-select {
        border: 1px solid $orders-measurement-wrapper-ant-select-border-success;
      }
    }
    .damaged {
      .ant-select {
        border: 1px solid $orders-measurement-wrapper-ant-select-border-damaged;
      }
    }
    .lost {
      .ant-select {
        border: 1px solid $orders-measurement-wrapper-ant-select-border-lost;
      }
    }
  }

  .custom-select-wrapper {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 6px;
    }
    .ant-select-arrow {
      top: 8px;
      right: 9px;
      padding-right: 0;
    }
  }

  .error-input {
    .ant-input {
      border-color: $error-text;
    }
  }
}

@media (max-width: 1550px) {
  .unit-wrapper {
    .input-container {
      height: 30px !important;
      .ant-input {
        padding: 4px;
        height: 30px !important;
      }
    }
  }
}
