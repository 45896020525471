@import 'assets/css/variables';

.input-pass-container .ant-input,
.input-container .ant-input {
  background: transparent;
  padding: 13px 24px;
  border: 2px solid $input-border;
  border-radius: 10px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: $input-color;
  position: relative;
}
.input-container .ant-input::placeholder,
.input-pass-container .ant-input-affix-wrapper input::placeholder {
  color: $input-placeholder-color;
}
.input-pass-container .ant-input-affix-wrapper {
  padding: 0;
  border-radius: 10px !important;
  position: relative;
  border: 2px solid $input-border;
}
.input-pass-container .ant-input-affix-wrapper .ant-input-suffix {
  margin: 0;
  position: absolute;
  right: 20px;
  top: calc(50% - 7px);
}
.input-pass-container .ant-input-affix-wrapper input {
  margin: 0;
  background: transparent;
  padding: 13px 24px !important;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: $input-color;
  position: relative;
}
.input-container,
.input-pass-container {
  position: relative;
}
.input-container span {
  font-family: 'Poppins';
  position: absolute;
  z-index: 3;
  left: 24px;
  transition: all 0.2s ease;
  user-select: none;
  color: $input-container-span;
  border-radius: 7px;
  p {
    display: inline-block;
    margin: 0;
  }
  .d-star {
    color: $input-error-color;
  }
}
.input-container .passive-label,
.input-pass-container .passive-label {
  top: 15px;
}
.input-container .active-label,
.input-pass-container .active-label {
  font-size: 12px;
  top: -8px;
  background-color: $input-bg-active-label;
  padding: 0 4px;
  color: $input-color-active-label 99;
}
.input-pass-container .placeholder {
  font-family: 'Poppins';
  position: absolute;
  z-index: 10;
  left: 24px;
  transition: all 0.2s ease;
  user-select: none;
  border-radius: 7px;
}
.input-pass-container span {
  color: $input-color;
  p {
    display: inline-block;
    margin: 0;
  }
  .d-star {
    color: $input-error-color;
  }
}

.ant-input[disabled]:hover,
.ant-input:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 2px;
}
.ant-input:focus,
.ant-input-affix-wrapper:focus {
  box-shadow: none;
  border-color: $input-border-affix-focus;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper {
  box-shadow: none;
  border-right-width: 2px;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $input-border;
}

.readonly-input-container{
  .ant-input[disabled] {
    background: transparent;
    color: $input-color !important;
    border-color: $input-border;
    cursor: default;
  }
}

@media (max-width: 1550px) {
  .input-container .passive-label,
  .input-pass-container .passive-label {
    left: 12px;
    top: 7px !important;
  }
  .input-container .active-label,
  .input-pass-container .active-label {
    left: 12px;
  }
  .input-container,
  .input-pass-container {
    height: 36px !important;
    .ant-input {
      padding: 12px;
      height: 36px !important;
    }
  }
}
