@import 'assets/css/variables';

.bins-container {
  .bins{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .ds-none{
    display: none;
  }
  .no-bins-block{
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: $locations-bins-no-bins-color;
  }
  .bins-button-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 32px;
    .button-wrapper {
      width: 120px;
      height: 40px;
      .ant-btn{
        height: 40px;
      }
    }
  }
}
