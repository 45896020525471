@import 'assets/css/variables';

.inventory-loc-container {
  border: 2px solid $location-border-df;
  border-radius: 10px;
  margin-top: 16px;
  .loc-header {
    background:$location-loc-header-bg;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px 18px;
    .location-name-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $location-btn-bg;
      border-radius: 7px;
      width: 100px;
      height: 30px;
      .ant-btn {
        width: 100px;
        height: 30px;
        border-radius: 7px;
      }
    }
    .loc-bin-name-button{
      width: fit-content;
      .ant-btn{
        width: auto;
        border: none;
      }
    }
    .button-wrapper .ant-btn[disabled] {
      background-color: $location-btn-bg-disabled;
      cursor: default;
    }

    .loc-header_custom-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #18384A;
      margin-left: 29px;
    }
  }
  .mu-titles-block {
    background: $location-title-block-bg;
    padding: 9px 18px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    color: $location-title-block-color;
    border-bottom: 1px solid $location-title-block-border;
  }
  .uom-block {
    background: transparent;
    padding: 9px 6px 16px 6px;

    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    filter: blur(0);
  }
  .bin-titles-block {
    background: transparent;
  }
  .floor-block {
    background: $location-title-block-bg;
  }
  .floor-header {
    background: $location-title-block-floor-bg;
  }
}
.inventory-loc-available-container {
  border: 2px solid $location-inventory-loc-available-border;
  cursor: pointer;
}
.inventory-loc-unavailable-container {
  border: 2px solid $location-inventory-loc-unavailable-border;
}

@media (max-width: 1550px) {
  .button-wrapper {
    height: 30px !important;
    .ant-btn {
      height: 30px !important;
      padding: 9.5px 15px;
    }
  }
}
