@import 'assets/css/variables';

.table-search {
  max-width: 600px;
  width: 100%;
  min-width: 320px;

  .ant-input-group-wrapper {
    border: none;
    width: 100%;
    height: 45px;
    margin-left: 48px;

    .ant-input-group {
      border: 2px solid $table-search-group-bg;
      border-radius: 12px;
      height: 100%;
      padding-left: 56px;
      padding-right: 60px;
      position: relative;
      display: flex;
      align-items: center;
    }
    .ant-input {
      border: none;
      border-radius: 12px;
      height: 100%;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      color: $table-search-group-df-color;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
    .ant-input:focus {
      box-shadow: none;
    }
    .ant-btn {
      border: none;
      height: 100%;
    }
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    position: absolute;
    left: auto;
    right: 0;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: calc(100% - 12px);
    width: 44px;
    background: transparent;
    border-left: 1px solid $table-search-group-bg;
    z-index: 2;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
    background: transparent;
    box-shadow: none;
    span {
      svg {
        fill: $table-search-group-fill-svg-df;
      }
    }
  }
  .search-wrapper {
    width: 100%;
    position: relative;

    .search-dropdown-block {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-right: 1px solid $table-search-group-bg;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      position: absolute;
      left: 50px;
      top: 6px;
      z-index: 2;
      padding: 0 14px;
      cursor: pointer;
    }
    .remove-search-value-cross{
      position: absolute;
      display: flex;
      right: 70px;
      top: 16px;
      z-index: 2;
      cursor: pointer;
    }
  }
}
