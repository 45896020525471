@import 'assets/css/variables';

.custom-select-with-search-wrapper {
  position: relative;
  width: 100%;

  .ant-select {
    width: 100%;
    height: 45px;
    padding: 0 13px;
    border: 2px solid $select-search-border-color;
    border-radius: 10px;
    .ant-select-selection-search-input {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      color: $select-search-df-color;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    border: none;
    border-radius: 10px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
  .readonly-select {
    .ant-select-selector {
      cursor: default !important;
    }
  }

  .custom-select-with-search-wrapper span {
    font-family: 'Poppins';
    position: absolute;
    z-index: 3;
    left: 24px;
    transition: all 0.2s ease;
    user-select: none;
    color: $select-search-wrapper-span-color;
    p {
      display: inline-block;
      margin: 0;
    }
    .d-star {
      color: $select-search-wrapper-span-error-color;
    }
  }
  .passive-label {
    top: 13px;
  }
  .active-label {
    font-size: 11px;
    top: -8px;
    background-color: $select-search-active-label-bg;
    padding: 0 4px;
    color: $select-search-active-label-color;
  }
  .placeholder {
    display: inline-flex !important;
    font-family: 'Poppins';
    position: absolute;
    z-index: 10;
    left: 24px;
    transition: all 0.2s ease;
    user-select: none;
    color: $select-search-active-label-color;
    p {
      margin: 0;
    }
  }
  .select-arrow-block {
    cursor: pointer;
    position: absolute;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 14px;
    top: 9px;
    height: 25px;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
    height: 100%;
  }
  .active-arrow {
    transform: rotate(180deg);
  }
}
.custom-select-with-search-disabled {
  cursor: not-allowed;
  input {
    cursor: not-allowed !important;
  }
}

.readonly-select-with-search-container {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
    cursor: default;
    .ant-select-selector {
      background: transparent;
      color: $input-color !important;
      border-color: $input-border;
      input {
        cursor: default !important;
      }
    }
  }
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) {
  border: 2px solid $error-border;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
}

@media (max-width: 1550px) {
  .custom-select-with-search-wrapper {
    .ant-select {
      height: 36px;
      padding: 0;
    }
    .select-arrow-block {
      top: 5px;
    }
    span {
      left: 15px !important;
    }
    .passive-label {
      top: 8px;
    }
  }
}
