@import 'assets/css/variables';

.rack-info-form-block {
  .input-container {
    height: 45px;
    .ant-input {
      height: 45px;
    }
  }

  .rack-info-name-input {
    margin-left: 48px;
  }
  .rack-def-values-block {
    border-top: 1px solid $location-rack-info-block-border;
    border-bottom: 1px solid $location-rack-info-block-border;
    padding: 16px 48px;
    .default-values-title {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      color: $dark-text;
    }
  }
  .rack-info-from-chekbox,
  .rack-info-from-textarea {
    padding: 0 48px;
    width: 100%;
  }
  .rack-info-save-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .button-wrapper {
      float: right;
    }
  }
  .rack-info-save-btn {
    float: right;
    width: 120px;
    margin-right: 58px;
    height: 40px;
    .ant-btn {
      height: 40px;
    }
  }
  .text-area-block {
    .ant-input[disabled] {
      background: transparent;
    }
  }
  .readonly-field {
    .ant-input {
      background: $location-rack-info-readonly-field-bg !important;
      cursor: default;
    }
    .ant-input[disabled]:hover {
      border-color: $location-rack-info-readonly-field-border;
    }
  }
}
