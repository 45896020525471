@import 'assets/css/variables';

.user-information-container {
  .user-inputs-wrapper {
    .user-info-select {
      width: 100%;
    }
    .input-container {
      height: 45px;
      .ant-input {
        height: 45px;
      }
      .passive-label {
        top: 12px;
      }
    }
    .ant-select {
      .ant-select-selector {
        padding: 0 24px !important;
      }
    }
  }
  .user-upload-image-container {
    .button-wrapper {
      height: 40px;
      .ant-btn {
        height: 40px;
      }
    }
  }
  .resend-pass-error-text {
    color: $error-text;
  }
  .user-info-buttons-wrapper {
    display: flex;
    .reset-pass-btn {
      margin-right: 12px;
      height: 32px;
    }
  }
}

@media (max-width: 1550px) {
  .user-information-container {
    .user-upload-image-container {
      .custom-upload-image-wrapper .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card {
        height: 90px;
      }
    }
  }
}
