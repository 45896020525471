@import 'assets/css/colorConstants';
@import 'assets/css/variables';

.outbound-items-wrapper {
  flex-direction: column !important;
  align-items: flex-end;
  .unclaim-button,
  .outbound-add-item-button,
  .outbound-go-to-pick-button,
  .confirm-button,
  .change-location-button,
  .outbound-claim-item-button {
    width: 132px;
    height: 40px;
    margin-bottom: 24px;
    button {
      height: 40px;
    }
  }

  .processing-out-order-text{
    font-family: 'Poppins';
    margin-bottom: 10px;
  }

  .processing-order-text .ant-btn[disabled]{
    background: transparent;
    border: none;
    cursor: default;
    span{
      color: $dark-text;
    }
  }

  .pos-relative{
    position: relative;
  }
  .outbound-claim-item-button,
  .unclaim-button,
  .confirm-button {
    margin-right: 14px;
  }
  .unclaim-button {
    .ant-btn {
      background: $white;
      border-color: $blue;
      span {
        color: $blue;
      }
    }
  }
  .change-location-button {
    width: auto;
  }
  .ds-flex {
    display: flex;
  }
  .end {
    justify-content: flex-end;
  }
  .no-items-text {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    color: $gray-text;
    width: 100%;
    text-align: center;
  }
}

.cancel-button {
  margin-left: 16px;
  .ant-btn {
    background: $white;
    border-color: $dark-border;
    span {
      color: $dark-text;
    }
  }
}

.ds-flex {
  display: flex;
}

.outbound-claim-popover{
  .ant-popover-content {
    right: -19px !important;
  }
}
.outbound-error-message-circle {
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid $chooser-loc-modal-error-message-circle-border;
  background: $chooser-loc-modal-error-message-circle-bg;
  position: absolute;
  top: -4px;
  right: 266px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10px;
  color: $chooser-loc-modal-error-message-circle-color;
  z-index: 2;
  cursor: pointer;
}
.claim-error-text{
  color: $chooser-loc-modal-error-message-circle-color;
}

