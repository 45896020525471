.inventory-locations-block {
  .other-location-btn {
    height: 40px;
    .ant-btn {
      height: 40px;
    }
  }
  .location-block{
    position: inherit;
  }
}

