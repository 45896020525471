@import 'assets/css/variables';

.login-form .button-wrapper {
  height: 48px;
  .ant-btn {
    height: 48px;
    margin-top: 24px;
  }
}
.forgot-password {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  margin-top: 24px;
  a {
    color: $link-color-df;
  }
}
.ant-form {
  width: 100%;
  .ant-form-item {
    margin: 0;
  }
}
.login-form .ant-form-item{
  margin-top: 24px;
}
