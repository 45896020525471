@import 'assets/css/variables';

.location-uom-container {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  .move-btn-col {
    display: flex;
    justify-content: flex-end;
  }
  .package-id-col {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .text-center{
    text-align: center;
  }
  .move-cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 30px;
    background: $location-uom-uint-move-cancel-btn-bg;
    border: 0.5px solid $location-uom-uint-move-cancel-btn-border;
    border-radius: 7px;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px;
    color: $location-uom-uint-move-cancel-btn-color;
    cursor: pointer;
  }
}

.carton-statuses-popover {
  .ant-popover-content {
    left: -19px !important;
  }
}

.carton-statuses-id {
  margin-right: 5px;
  margin-top: 3px;
  border: 1px solid;
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  text-align: center;
  font-size: 10px;
  border-radius: 50%;
  cursor: pointer;

    &.Inbound {
      border-color: $location-uom-uint-carton-statuses-border-inbound;
    }
    &.Outbound{
       border-color: $location-uom-uint-carton-statuses-border-outbound
    }

    &.Allocated{
       border-color: $location-uom-uint-carton-statuses-border-allocated;
    }

    &.Processing{ 
      border-color: $location-uom-uint-carton-statuses-border-processing;
    }

    &.Reserved {
      border-color: $location-uom-uint-carton-statuses-border-reserved;
    }

    &.Picked {
      border-color: $location-uom-uint-carton-statuses-border-picked;
    }
}

.custom-popover-content-status {
  font-size: 10px;
  font-weight: 500;
  .title {
    display: inline-block;
    margin-right: 5px;
    width: 40%;
    color: $gray-text;
    font-weight: 300;
  }

  .title-info {
    width: 50%;
    display: inline-block;
    align-items: left;
  }
}