.legenda-item-block {
  display: flex;
  align-items: center;
  .color-block {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
}
