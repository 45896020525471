@import 'assets/css/variables';

.new-rack-modal-block {
  .input-container {
    height: 45px;
    .ant-input {
      height: 45px;
      padding: 13px 10px 13px 20px;
    }
  }
  .new-rack-def-values-block {
    border-top: 1px solid $location-new-rack-border;
    border-bottom: 1px solid $location-new-rack-border;
    padding: 16px 48px;
    .default-values-title {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      color: $location-new-rack-value-title;
    }
  }
  .new-rack-lev-col-block{
      padding: 0 48px;
  }
 .rack-info-from-textarea{
    padding: 0 48px;
    width: 100%;
  }
 
}
.new-rack-modal-button{
    margin-right: 48px;
    margin-top: 12px
}
