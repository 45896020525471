@import 'assets/css/variables';

.custom-select-wrapper {
  width: 100%;
  position: relative;

  .ant-select {
    width: 100%;
    height: 45px;
    border: 2px solid $select-border;
    border-radius: 10px;
    .ant-select-selection-search-input,
    .ant-select-selection-item {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      color: $select-color;
    }
    .ant-select-arrow{
      padding-right: 7px;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    border: none;
    border-radius: 10px;
    padding: 0 24px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }

  .custom-select-wrapper span {
    font-family: 'Poppins';
    position: absolute;
    z-index: 3;
    left: 24px;
    transition: all 0.2s ease;
    user-select: none;
    color: $select-wrapper-span-color;
  }
  .passive-label {
    top: 14px;
  }
  .active-label {
    font-size: 11px;
    top: -8px;
    background-color: $select-label-bg-color;
    padding: 0 4px;
    color: $select-active-label-color;
  }
  .placeholder {
    font-family: 'Poppins';
    position: absolute;
    z-index: 10;
    left: 24px;
    transition: all 0.2s ease;
    color: $select-active-label-color;
    user-select: none;
    p {
      display: inline-flex;
      margin: 0;
    }
    .d-star {
      margin: 0;
      color: $select-color-error;
    }
  }
}

.default-select-dropdown,
.default-select-with-search-dropdown {
  border: 1px solid $select-border;
  box-shadow: 0px 5px 47px #7b7b7b1a;
  border-radius: 10px !important;
  min-width: 150px !important;

  .ant-select-item {
    padding-left: 20px;
    font-weight: 400 !important;

    &:hover {
      background: $bg-selected-component !important;
      div{
        color: $dark-text;
        font-weight: 500 !important;
      }
    }
  }
  .ant-select-item-option-active {
    background: $bg-ant-select-color !important;
    color: $bg-df-hover-color !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    background-color: $bg-ant-select-color !important;
  }
}

.custom-select-disabled {
  cursor: not-allowed;
  input {
    cursor: not-allowed !important;
  }
}

.readonly-custom-select-container {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input){
    cursor: default;
    .ant-select-selector {
      background: transparent;
      color: $input-color !important;
      border-color: $input-border;
      cursor: default;
      input {
        cursor: default !important;
      }
    }
  }
}

@media (max-width: 1550px) {
  .custom-select-wrapper {
    .ant-select {
      height: 36px;
    }
    .passive-label {
      top: 8px;
    }
    .active-label {
      left: 12px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 12px;
    }
  }
}
