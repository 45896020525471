@import 'assets/css/variables';

.upload-file-wrapper {
  .file-upload-container {
    border: 2px dashed $border-light-gray;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    .ant-upload {
      display: flex;
      align-items: center;
      .browse-btn {
        width: 130px;
        height: 40px;
        box-shadow: 0px 3px 15px rgba(150, 157, 156, 0.15);
      }
      .upload-file-text {
        font-family: 'Poppins';
        font-weight: 300;
        color: $input-label-color;
        margin-right: 32px;
      }
    }
  }
  .ant-upload-list {
    display: none;
  }
}
