@import 'assets/css/variables';

.blured-inventory-loc-block {
  .ant-collapse {
    filter: blur(0);

    .ant-collapse-header {
      filter: blur(1px) brightness(95%);
    }

    .ant-collapse-content {
      background-color: $view-inventory-collapse-content;
      > * {
        filter: blur(1px) brightness(95%);
      }

      filter: blur(0);

      .ant-collapse-content-box {
        filter: blur(0);
      }
      .mu-titles-block {
        filter: blur(1px) brightness(90%);
      }

      .location-uom-container {
        filter: blur(1px) brightness(90%);
      }

      .move-clicked-state-loc-container {
        background: $view-inventory-move-clicked-state-loc-bg;
        border-radius: 15px;
        filter: blur(0);
      }

      .other-location-btn {
        box-shadow: 0px 5px 15px $view-inventory-box-shadow;
        border-radius: 10px;
      }
    }
  }
}
.inventory-table {
  td:first-child {
    position: relative;
  }
}
