@import 'assets/css/variables';

.custom-checkbox-wrapper {
  .ant-checkbox-wrapper-checked {
    &:hover{
      .ant-checkbox::after{
        border: none;
        border-radius: 5px;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: $checkbox-bg-inner;
        border-color: $checkbox-border-color;
        &::after {
          border-color: $checkbox-bg-inner-after-color;
          left: 32.5%;
          border-radius: 0;
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    span {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 16px;
      color: $checkbox-wrapper-color;
    }
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 22px;
        height: 22px;
        border-radius: 5px;
        border: 1px solid $checkbox-bg-inner;
      }
    }
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        background: transparent;
      }
    }
  }
}
.disabled-and-checked {
  .ant-checkbox {
    .ant-checkbox-inner {
      background: $checkbox-bg-disabled-bg !important;
      border-radius: 5px;
      &::after {
        border-color: $checkbox-bg-inner-after-disabled-color !important;
      }
    }
  }
}
