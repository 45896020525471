@import 'assets/css/variables';

.created-item-wrapper {
  padding: 7px 12px 22px;
  align-items: baseline;

  .center {
    display: flex;
    align-items: center;
  }
  .max-quantity-wrapper {
    display: flex;
    flex-direction: column-reverse;
    .max-quantity-info {
      display: flex;
      font-family: 'Poppins';
      font-weight: 300;
      font-size: 10px;
      color: $body-text-color;
      margin-left: 5px;
      margin-bottom: 3px;

      span {
        display: flex;
        opacity: 0.7;
        margin-left: 3px;

        .quantity-count {
          margin-left: 3px;
        }
      }
    }
  }
  .uom-package-id-select {
    .ant-select {
      width: 140px !important;
      max-width: 140px !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      position: relative;
      .package-id-select-option {
        height: 60px;
        position: absolute;
        top: -2px;
      }
    }
  }
}
