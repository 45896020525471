@import 'assets/css/variables';

.custom-confirmatiom-modal {
    .ant-modal-content {
      height: 200px;
      min-height: 200px;
      .ant-modal-body {
        height: auto;
        padding-top: 0;
        .dialog-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: auto;
          .confirmation-text {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 16px;
            color: $dark-text;
          }
        }
      }
    }
  }