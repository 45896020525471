@import 'assets/css/variables';

.custom-search-wrapper {
  position: relative;
  .ant-input-group-wrapper {
    border: none;
    height: 38px;

    .ant-input-group {
      border: 2px solid $search-input-border;
      border-radius: 12px;
      height: 100%;
      padding-left: 24px;
      position: relative;
      display: flex;
      align-items: center;
    }
    .ant-input {
      border: none;
      border-radius: 12px;
      height: 100%;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      color: $search-input-color;
      border-top-right-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
    }
    .ant-input:focus {
      box-shadow: none;
    }
    .ant-btn {
      border: none;
      height: 100%;
    }
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
    background: transparent;
    box-shadow: none;
    z-index: 2;
    right: 40px;
    border-left: 1px solid $search-input-border;
    span {
      svg {
        fill: $search-input-fill-svg;
      }
    }
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child{
    background: transparent;
  }
  .remove-search-value-cross {
    position: absolute;
    top: 10px;
    right: 53px;
    z-index: 2;
    cursor: pointer;
  }
}
