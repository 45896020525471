@import 'assets/css/colorConstants';

.outbound-item-container {
  border: 2px solid $border-light-gray;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  .item-header {
    display: flex;
    align-items: center;
    padding: 12px;
    position: relative;
    background: linear-gradient(290.69deg, #f0f5f7 0%, #e7f0f2 100%, #f2f9fb 100%);
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    .item-header-text {
      display: inline;
      margin-right: 7px;
      font-family: 'Poppins';
      font-weight: 300;
      font-size: 16px;
      color: $dark-text;
      &:first-child {
        margin-right: 20px;
      }
    }

    .three-dots-block {
      position: absolute;
      right: 10px;
      top: 12px;
      padding: 0 6px;
      cursor: pointer;
      svg {
        path {
          fill: $dark-text;
        }
      }
      .remove-item-dropdown-wrapper {
        position: relative;
        .custom-dropdown-container {
          .button-wrapper {
            .ant-btn {
              span {
                color: $error-text;
              }
            }
          }
        }
      }
    }
  }
  .titles {
    background: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);
    padding: 12px;
    .uom-title-header {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 13px;
      display: inline;
      color: $dark-text;
      margin: 0;
    }
  }
  .uom-input {
    .ant-input {
      text-align: left;
      padding: 8px;
    }
  }

  .uom-input .ant-input {
    height: 30px !important;
    font-weight: 300;
    font-size: 13px;
    max-width: 60px;
  }

  .error-input {
    .ant-input {
      border-color: $error-text;
    }
  }
  .custom-select-wrapper {
    .ant-select {
      height: 30px;
      max-width: 100px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 6px;
    }
    .ant-select-arrow {
      top: 12px;
    }
  }
}
