@import 'assets/css/variables';

.custom-radio-button-wrapper {
  width: fit-content;
  .ant-radio {
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border: 1px solid $radio-btn-border;
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border: 5px solid $radio-btn-border;
    }
    .ant-radio-inner::after {
      display: none;
    }
  }
  .ant-radio-wrapper {
    .radio-title {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 16px;
      color: $radio-btn-color;
    }
  }
}
