@import 'assets/css/variables';

.item-container {
  border: 2px solid $orders-item-uom-container-border;
  border-radius: 15px;
  margin-bottom: 16px;
  .uom-title-header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 13px;
    color: $dark-text;
    margin: 0;
    display: inline-flex;
    align-items: center;
  }
  .total-text,
  .total-text span {
    font-weight: 600;
    padding-right: 5px;
  }
  .total-text{
    text-align: center;
  }
 

  .bad-result{
    color: $error-text;
    font-weight: 600;
  }

  .item-header {
    display: flex;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: $orders-item-uom-item-header-bg;
    padding: 13px 24px;
    position: relative;
    .item-header-text {
      display: inline;
      font-family: 'Poppins';
      margin-right: 8px;
      font-weight: 300;
      font-size: 16px;
      color: $orders-item-uom-item-header-color;
      &:first-child {
        margin-right: 20px;
      }
    }
    .three-dots-block {
      position: absolute;
      right: 10px;
      top: 12px;
      padding: 0 6px;
      cursor: pointer;
      svg {
        path {
          fill: $dark-text;
        }
      }
      .remove-item-btn {
        .ant-btn {
          span {
            color: $error-text;
          }
        }
      }
    }
    p:first-child {
      margin-right: 28px;
    }
  }

  .expected-title, .result-title {
    padding: 12px 0 0 16px;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
  }
  .expected-title{
    color: $blue;
  }
  .no-expected-text{
    margin: 0;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px;
    color: $dark-text;
    padding: 4px 16px;
    background: $secondary-light;
  }
  .result-row-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: $white;
    padding: 16px 16px 6px 16px;
    .result-title{
      padding: 0;
      color: $orange;
      background: $white;
    }
  }

  

  .titles {
    background: $orders-item-uom-titles-bg;
    padding: 12px;
    margin: 0 !important;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .add-button {
      display: flex;
      justify-content: flex-end;
      height: 28px !important;
      button {
        width: 72px;
        height: 28px !important;
      }
    }
  }

  .selects-wrapper {
    padding: 12px;
    .conditions-block {
      position: relative;
    }
  }

  .result-selects-wrapper {
    padding: 12px;
    .conditions-block {
      position: relative;
    }
    background: $white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .item-container-footer {
    border-top: 2px solid $orders-item-uom-container-border;
    padding: 16px;
  }

  .result-item-container-footer{
    background: $white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .total-block {
    background: $secondary-light;
    height: 32px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Poppins';
    color: $dark-text;
    .col-total {
      display: flex;
      justify-content: flex-end;
      margin-right: 5px;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .display-none {
    display: none;
  }
  .total-block-with-result-uoms{
    margin-bottom: 0;
  }
  .incorrect-total-text{
    span{
      color: $error-text;
    }
  }
}

.readonly-item-container {
  background: $orders-item-uom-titles-bg;
}
