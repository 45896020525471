.outbound-order-wrapper {
  .new-outbound-btn {
    height: 40px;
  }
  
}
.outbound-orders-items-right-card .ant-collapse-item:last-child > .ant-collapse-content {
    padding: 0 !important;
    .ant-collapse-content-box{
      padding-bottom: 0;
    }
  }
  .orders-right-wrapper{
    .right-wrapper-block{
      .right-wrapper-header{
        font-weight: 500;
      }
    }
    .right-wrapper-order-status{
      font-size: 17px;
      font-weight: 300;
    }
  }

 
 