@import '../../../../../../assets/css/colorConstants.scss';

.items-with-locations-container {
  .uom-info-block {
    padding: 20px 12px;
    color: $dark-text;
  }
  .locations-title {
    background: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    color: $dark-text;
    border-top: 2px solid $border-light-gray;
    &:first-child {
      border-right: 2px solid $border-light-gray;
    }
    .ant-col {
      display: flex;
      justify-content: center;
    }
  }

  .location-name-block {
    width: 90px;
    background: $white;
    border-radius: 7px;
    height: 30px;
    border: 1px solid $border-card-light-border;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: $dark-text;
  }
  .bin-block {
    width: 120px;
  }
  .bin-name-block{
    display: block !important;
    text-align: center;
    height: 40px;
    margin: 0;
    .bin-name{
      margin: 0;
      margin-top: 20px;
    }
  }
  .uom-input {
    .ant-input {
      background: $white;
    }
  }
  .picking-button {
    width: 60px;
    margin-bottom: 6px;
    .ant-btn {
      background: $white;
      border-color: $blue;
      span {
        color: $blue;
      }
    }
  }
  .justify-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-center {
    text-align: center;
  }
  .uom-loation-info {
    padding: 8px 6px;
    &:nth-child(odd) {
      border-right: 2px solid $border-light-gray;
    }
  }
  .uoms-group {
    background: $border-light-gray;
    padding: 8px 6px 2px 6px;
    .uom-input {
      margin-bottom: 6px;
    }
  }

  .uom-in-stock-custom-component {
    position: relative;

    .uom-in-stock-custom-component_popover {
      display: flex;
      align-items: center;
      left: -19px;
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  .uom-location-custom-component_popover {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer;
      width: 100%;
      justify-content: center;
  }
}

.uom-in-stock-popover {
  .ant-popover-content {
    left: -19px !important;

    .ant-popover-inner {
      .ant-popover-inner-content {
        min-width: auto;
      }
    }
  }
}

.uom-location-popover {
  .ant-popover-content {
    right: -20px !important;
    .ant-popover-inner {
      .ant-popover-inner-content {
        min-width: auto;
      }
    }
  }
}

@media (max-width: 1550px) {
  .uom-input {
    height: 30px !important;
    .ant-input {
      height: 30px !important;
    }
  }
}
