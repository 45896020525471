@import 'assets/css/variables';

.item-textarea-desc-wrapper {
  .ant-input[disabled] {
    background: transparent;
  }
}
.item-info-form-block {
  .input-container {
    height: 45px;
    .ant-input {
      height: 45px;
      cursor: default;
    }
    .passive-label {
      top: 11px;
    }
  }
}

.inventory-info-row {
  margin-bottom: 16px;
  .ant-col {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    color: $item-information-inventory-color;
  }
}
.item-info-col {
  text-align: end;
  margin-bottom: 10px;
}
.info-col-with-border {
  border-bottom: 1px solid $item-information-inventory-col-border;
}
.inventory-total-row {
  padding-top: 16px;
  .ant-col {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    color: $item-information-inventory-color;
  }
}

@media (max-width: 1550px) {
  .text-area-block{
    height: 90px;
  }
}
