@import 'assets/css/variables';

.warehouse-info-card {
  border: 2px solid #e9f1f2;
  border-radius: 10px;
  margin-bottom: 16px;
  .card-title-block {
    background: #e9eff2;
    padding: 9px 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .warehouse-name {
      font-family: 'Poppins';
      font-weight: 500;
      padding: 2px 12px;
      background: #acbcc6;
      border-radius: 7px;
      color: white;
      width: fit-content;
    }
  }
  .card-header {
    background: linear-gradient(110.69deg, #f9fbfc 0%, #f6f9fa 100%, #f5f9fa 100%);
    padding: 9px 24px;
    color: $dark-text;
    font-family: 'Poppins';
    font-weight: 600;
  }
  .card-body {
    padding: 24px;
    background: transparent;
  }

}
