@import '../../../assets/css/colorConstants.scss';

.custom-dropdown-container {
  position: absolute;
  right: -20px;
  background: $white;
  border: 1px solid $border-light-gray;
  box-shadow: 0px 5px 47px rgba(123, 123, 123, 0.1);
  border-radius: 7px;
  padding: 8px 0;
  z-index: 10;
  .button-wrapper{
    height: 30px;
    .ant-btn{
      border-radius: 0;
      background: $white;
      border: none;
      &:hover{
        background: $light-gray;
      }
    }
  }
}
.hide-dropdown{
    display: none;
}
.dropdown-visible{
    display: block;
}
