@import 'assets/css/colorConstants';

.out-order-consinee-wrapper {
  .consignee-save-button {
    width: 120px;
    float: right;
  }
  .radio-group-block{
    width: 100%;
    display: flex;
  }
}
