@import 'assets/css/variables';

.item-inventory-info-wrapper {
  justify-content: center;
  .ext-id-title {
    margin-bottom: 6px;
  }
  .item-field {
    color: $dark-text;
    font-family: 'Poppins';
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .item-field-label {
      margin: 0;
      color: $gray-text;
      font-family: 'Poppins';
      font-weight: 300;
      font-size: 12px;
    }
  }
  .horisontal-line {
    width: 100%;
    height: 1px;
    background: $border-light-gray;
    margin: 12px 0;
  }
  .calc-width {
    width: calc(100% - 12px);
  }
  .col-external {
    display: flex;
    flex-direction: column;
  }
}

