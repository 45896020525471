@import 'assets/css/variables';

.custom-modal-window-wrapper {
  .ant-modal-content {
    min-height: 300px;
    height: 500px;
    border-radius: 15px !important;
    .ant-modal-body {
      height: calc(100% - 56px);
      border-radius: 15px;
      overflow: hidden;

      .dialog-body {
        padding: 25px 0 32px 0;
        height: 100%;
      }
    }
    .ant-modal-header {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 56px;
      border-bottom: 2px solid $modal-window-border-color;
      padding: 13px 40px !important;
    }
    .ant-modal-close {
      right: 20px;
    }
  }
}
.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal {
    top: 0;
  }
}
