@import 'assets/css/variables';

.related-user-card-item {
  background: $store-related-card-df-item-bg;
  border-radius: 10px;
  margin-bottom: 8px;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
  color: $store-related-card-df-color;
  padding: 25px 24px;
  .remove-user-dropdown{
    bottom: 24px;
    right: -10px;
  }
  .three-dots-block {
    display: flex;
    justify-content: flex-end;
  }
  .remove-user-btn {
    .ant-btn {
      span {
        color: $error-text;
      }
    }
  }
  .edit-user-remit{
    .ant-btn {
      span {
        color: $dark-text;
      }
    }
  }
  .user-role-select{
    .ant-select{
        background: transparent;
        border: none;
        width: auto;
        .ant-select-arrow{
          right: -10px;
        }
        .ant-select-selector{
            background: transparent;
            padding: 0;
        }
    }
  }
}
