.current-row-icon {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: -6px;
  cursor: pointer;

  .current-row-icon-popup {
    position: absolute;
    z-index: 99;
  }

  .custom-dropdown-container {
    top: 25px;
    right: -5px;
  }
}

.create-report-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .title-info {
    margin: 0 5px;
    font-size: 12px;
  }

  .btn-create-report,
  .btn-print-report {
    display: flex;
    align-items: center;
    height: 40px;
    flex-direction: row-reverse;
  }

  .btn-print-report {
    min-width: 120px;
  }

  .body-report {
    height: 100%;
    margin-top: 30px;
    overflow: hidden;
    .autorized-signature {
      display: none;
      width: 144px;
      text-align: center;
      position: absolute;
      padding-top: 144px;
      font-weight: 300;
      font-size: 8px;
    }
  }
}

.current-row-modal {
  .ant-modal-content {
    height: 100%;
    max-height: 740px;
    .ant-modal-body {
      padding: 0;
    }
  }
}
.date-picker {
  padding: 0 24px;
}

.btn-print-report-wrap {
  padding: 24px;
}
