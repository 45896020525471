$secondary: #adbdc7;
$secondary-light: #e9eff2;
$orange: #eea230;
$success: #0bdeab;
$success-light: #67a086;

$df-inbound-color: #0bdeab;
$df-outbound-color: #1eaae7;
$df-allocated-color: #b5d9e4;
$df-processing-color: #acbcc6;
$df-reserved-color: #eea230;
$df-picked-color: #fdf428;

$dark-color-hight: #00000013;
$dark-color-light: #0a485b;
$blue: #3dc4ef;
$blue-box-shadow: #1cbbff;
$dark-text: #18384a;
$dark-border: #18384a;
$gray-text: #acbcc6;
$gray-light: #e9f1f2;
$error-text: #ff5f68;
$error-bg: #ff5f68;
$error-box-shadow: #ff0000;
$error-border: #ff5f68;
$light-gray: #f0f5f7;
$light-gray-hover: #c4c4c4;
$light-gray-selected: #b4b4b4;
$dark-light-select-active: #0a485b99;

$light-gray-active: #dadada;
$white: white;
$white-light: #f5f5f5;
$white-light-inventory: #f2f2f2;
$white-light-items: #fafbfc;
$light-gray-background: #e8f0f3;

$border-light-gray-active: #acbcc6;
$border-light-gray: #e9f1f2;
$border-light-gray-focus: #b5d9e4;
$border-light-gray-disabled: #d9d9d9;
$border-card-light-border: #cdd8df;
$border-card-light-color: #cdd8df;
$hover-light-background: #f8fafb;
$table-gray-border: #e4ebed;

$gray-bin: #e9eff2;
$blue-legenda: #1eaae7;
$yellow-legenda: #fdf428;
$gray-border: #acacac;
$dark-light: #3c3c3c;
$popover-wrapper: #f0f0f090;

$gray-avatar-df: #b8b8b8;

$bg-readonly: #f9fbfc;
$bg-df-line: #e9f1f2;
$bg-hover-white-light: #f7fafb;
$confirm-alert-overlay-color: #00000046;
$bg-gray: #CDD8DF;

$box-shadow-dark: 0px 18px 50px #2f343508;
$box-shadow-dark-light: #80808033;
$box-shadow-login-block: #065a654d;
$box-shadow-img-trash: #969d9c26;
$box-shadow-btn-dropdown: #7b7b7b1a;
$box-shadow-wrapper-block: #7b7b7b1a;
$box-shadow-wrapper-block-light: #367a8f1f;

$box-shadow-rack-hover: #969d9c1a;
$box-shadow-bin-active: #969d9c33;

$box-shadow-inventory: #009abc40;

$box-shadow-white-light-color: #eff4f3;
$text-404-df: #444444;
