@import 'assets/css/variables';

.related-users-right-card{
     .ant-collapse-item:last-child > .ant-collapse-content{
       padding: 6px;
    }
}
.no-data-block {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-top: 48px;
  color: $view-stores-no-data-df-color;
}