@import 'assets/css/variables';

.bin-container {
  width: 100%;
  margin-bottom: 40px;
  background: $locations-bin-df-bg;
  border: 2px solid $locations-bin-df-border;
  border-radius: 10px;
  .bin-header {
    background: $locations-bin-container-bg-header;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    .bin-name {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 14px;
      color: $locations-bin-df-color;
    }
    .delete-bin-button-wrapper {
      width: 30px;
      height: 30px;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  .input-container {
    height: 45px;
    .active-label{
      left: 12px;
    }
    .passive-label{
      top: 13px;
      left: 6px;
      font-size: 12px;
    }
    .ant-input {
      height: 45px;
    }
  }
}
.delete-bin-alert-block {
  width: 100%;
  height: 147px;
  background: $locations-bin-df-bg;
  border: 1px solid $locations-bin-df-border;
  box-shadow: $location-bin-delet-alert-box-shadow;
  border-radius: 10px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px;
    color: $locations-bin-df-color;
  }
  .bin-alert button {
    width: 70px;
    height: 28px;
    border-radius: 7px;
    margin-left: 16px;
    .ant-bnt {
      height: 28px;
      border-radius: 7px;
    }
  }
  .no-btn {
    .ant-btn {
      background: $location-bin-ant-btn-bg;
      border: 0.5px solid $location-bin-ant-btn-border;
      span {
        color: $location-bin-ant-btn-color;
      }
    }
    &:hover {
      .ant-btn {
        border: 1px solid $location-bin-ant-btn-border;
        box-shadow: $location-bin-ant-btn-box-shadow-hover;
        border-radius: 7px;
        span {
          font-weight: 600;
          color: $location-bin-ant-btn-color;
        }
      }
    }
    &:active {
      .ant-btn {
        border: 1px solid $location-bin-ant-btn-border;
        box-shadow: 0px 3px 15px $location-bin-ant-btn-box-shadow;
        border-radius: 7px;
        span {
          font-weight: 600;
          color: $location-bin-ant-btn-color;
        }
      }
    }
    .ant-btn:focus {
      border-color: $location-bin-ant-btn-border !important;
    }
  }
}

@media (max-width: 1550px) {
  .delete-bin-alert-block {
    .button-wrapper {
      height: 28px !important;
      .ant-btn {
        height: 28px !important;
      }
    }
  }
}
