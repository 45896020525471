@import 'assets/css/variables';

.custom-date-picker {
  .ant-picker {
    position: relative;
    padding-left: 24px;
    border-radius: 10px;
    height: 40px;
    width: 136px;
    border: 2px solid $border-color-light;

    .ant-picker-clear > * {
      padding: 2px;
    }
    &:hover {
      border-right-width: 2px;
    }
  }

  .date-placeholder {
    position: absolute;
    top: 0;
    font-size: 12px;
    top: -8px;
    color: #acbcc6;
    background-color: white;
    padding: 0 4px;
    left: 15px;
  }

  @media (max-width: 1550px) {
    .date-placeholder {
      left: 8px;
    }
  }
}

.readonly-date-picker{
  .ant-picker.ant-picker-disabled{
    border-color: $border-color-light;
    cursor: default;
    background: transparent;
    input{
      cursor: default;
      color: $dark-text;
    }
  }
}

@media (max-width: 1550px) {
  .custom-date-picker {
    .ant-picker {
      height: 30px;
    }
  }
}
