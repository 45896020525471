@import 'assets/css/variables';

.loader-wrapepr {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $loader-wrapper-bg;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  border: solid 8px $loader-wrapper-lds-ring;
  border-radius: 50%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: -8px;
  border: 8px solid $loader-wrapper-lds-ring-div-border;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $loader-wrapper-lds-ring-div-border transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
