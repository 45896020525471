@import 'assets/css/variables';

.common-table-wrapper {
  height: calc(100% - 40px);
 
  .table-select-search-wrapper {
    display: flex;
    align-items: center;
    .table-select {
      width: 200px;
      margin-left: 48px;
      .select-arrow-block {
        top: 13px;
      }
    }
    .table-filter-checkbox-wrapper{
      margin-left: 32px;
    }
  }

  .common-table {
    margin-top: 20px;
    .ant-table {
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        height: 14px;
      }
      &::-webkit-scrollbar-track {
        background: $common-table-scrollbar-bg !important;
        border-left: 1px solid $common-table-scrollbar-color;
      }
      &::-webkit-scrollbar-thumb {
        height: 14px;
        border-radius: 5px !important;
        background: $common-table-scrollbar-bg-thumb !important;
      }
      .ant-table-container {
        min-width: 600px;
      }
      table {
        table-layout: auto !important;
        colgroup{
          col:last-child{
            display: none;
          }
        }
      }

      .ant-table-body {
        height: 600px;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          width: 14px;
        }
        &::-webkit-scrollbar-track {
          background: $common-table-scrollbar-track !important;
          border-left: 1px solid $common-table-scrollbar-track-border;
        }
        &::-webkit-scrollbar-thumb {
          width: 14px;
          border-radius: 5px !important;
          background: $blue !important;
        }
      }
    }
    .ant-table-thead > tr {
      th {
        background: none;
        border: none;
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 16px;
        color: $dark-text;
        white-space: nowrap;
        &:first-child {
          padding-left: 48px;
        }
        &.ant-table-cell-scrollbar {
          display: none;
        }
      }
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      content: none;
    }
    tbody {
      tr {
        height: 56px;
        td {
          font-family: 'Poppins';
          font-weight: 300;
          font-size: 14px;
          color: $dark-text;
          &:first-child{
            padding-left: 48px;
          }
        }
        &.ant-table-row:nth-child(even) {
          td.ant-table-cell {
            border-top: 0.5px solid $common-table-cell-border;
            border-bottom: 0.5px solid $common-table-cell-border;
            background: $common-table-cell-bg;
            &:first-child {
              border-left: 0.5px solid $common-table-cell-border;
            }
            &:last-child {
              border-right: 0.5px solid $common-table-cell-border;
            }
          }
          &:hover {
            td.ant-table-cell {
              border-top: 0.5px solid $common-table-cell-border-hover;
              border-bottom: 0.5px solid $common-table-cell-border-hover;
              box-shadow: $common-table-cell-box-shadow-hover;
              font-weight: 600;
              &:first-child {
                border-left: 0.5px solid $common-table-cell-border-hover;
              }
              &:last-child {
                border-right: 0.5px solid $common-table-cell-border-hover;
              }
            }
          }
        }
        &.ant-table-row:nth-child(odd) {
          background: transparent;
          td.ant-table-cell {
            border-top: 0.5px solid $common-table-cell-border-child;
            border-bottom: 0.5px solid $common-table-cell-border-child;
            background: $light-gray;
            &:first-child {
              border-left: 0.5px solid $common-table-cell-border-child;
            }
            &:last-child {
              border-right: 0.5px solid $common-table-cell-border-child;
            }
          }
          &:hover {
            td.ant-table-cell {
              border-top: 0.5px solid $common-table-cell-border-hover;
              border-bottom: 0.5px solid $common-table-cell-border-hover;
              box-shadow: $common-table-cell-box-shadow-hover;
              background: $hover-light-background;
              font-weight: 600;
              &:first-child {
                border-left: 0.5px solid $common-table-cell-border-hover;
              }
              &:last-child {
                border-right: 0.5px solid $common-table-cell-border-hover;
              }
            }
          }
        }

        &.active-row:nth-child(even),
        &.active-row:nth-child(odd) {
          border-radius: 12px;
          td {
            color: $white;
            border-top: 0.5px solid $common-table-row-border-active !important;
            border-bottom: 0.5px solid $common-table-row-border-active !important;
            box-shadow: 0px 22px 61px -2px transparent, 0px 1.79259px 4.97037px -2px transparent;
            background: $common-table-row-bg-active !important;

            &:first-child {
              border-left: 0.5px solid $common-table-row-border-active !important;
            }
            &:last-child {
              border-right: 0.5px solid $common-table-row-border-active !important;
            }
          }
          &:hover {
            td.ant-table-cell {
              border-top: 0.5px solid $common-table-row-border-hover;
              border-bottom: 0.5px solid $common-table-row-border-hover;
              text-shadow: none;
              &:first-child {
                border-left: 0.5px solid $common-table-row-border-hover;
              }
              &:last-child {
                border-right: 0.5px solid $common-table-row-border-hover;
              }
            }
          }
        }
      }
    }
    tbody {
      tr:nth-child(odd) {
        background: $common-table-tbody-tr-bg;
      }
    }
    ul {
      display: none;
    }
  }

  .table-pagination-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-table-placeholder{
    td{
      border-bottom: none;
    }
  }
}

@media (max-width: 1550px) {
  .common-table-wrapper {
    .custom-select-with-search-wrapper {
      .ant-select {
        height: 45px;
      }
      svg {
        top: 21px;
      }
    }
  }
}
