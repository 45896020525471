@import 'assets/css/variables';

.forgot-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .forgot-title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin: 20px 0 40px;
  }
}

.forgot-text-before {
  text-align: center;
  margin-bottom: 40px;
  p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
}

.forgot-text-after {
  text-align: center;
  margin: 45px 0;
  p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
  }
}

.forgot-text-after-small {
  text-align: center;
  margin-bottom: 20px;
  p {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }
}

.closed-icon {
  font-size: 15px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: $gray-text;
}

.closed-icon :hover {
  font-size: 17px;
}

.forgot-form {
  display: flex;
  flex-direction: column;
}

.forgot-error-text {
  color: $error-text;
}

.forgot-sbm-btn {
  margin-top: 28px;
  height: 50px;
}

.forgot-timer {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 8px;
  color: $gray-text;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  div {
    margin: 0 2px;
    color: $gray-text;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
