@import 'assets/css/variables';

.add-user-modal-window {
    .ant-modal-body {
      padding: 0;
      .dialog-body {
        .common-table-wrapper .table-select-search-wrapper .add-related-users-table-select {
          height: 38px;
          width: 180px;
          margin-left: 40px;
          .ant-select {
            height: 38px !important;
            padding: 0;
          }
          .select-arrow-block{
            right: 6px;
            top: 7px;
          }
        }
        .table-search {
          .search-wrapper {
            min-width: 200px !important;
            height: 38px;
            .ant-input-group-wrapper {
              height: 38px;
              margin-left: 16px;
              .ant-input-group {
                padding-left: 40px;
              }
            }
            .remove-search-value-cross{
              top: 12px;
            }
            .search-dropdown-block {
              left: 13px;
              top: 3px;
            }
          }
        }
      }
    }
  }