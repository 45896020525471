@import 'assets/css/variables';

.not-found-page-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-404 {
    font-family: 'Poppins';
    font-size: 67px;
    letter-spacing: 3.2px;
    word-spacing: 2.8px;
    color: $blue;
    font-weight: 700;
    text-decoration: none solid $view-404-df-text;
    font-style: normal;
    font-variant: small-caps;
    text-transform: none;
    margin-bottom: 0;
  }
  .error-text,
  .error-text-not-found {
    font-family: 'Poppins';
    font-size: 37px;
    color: $dark-text;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .error-text-not-found {
    margin-bottom: 40px;
  }
  .go-back-404-btn {
    width: 260px;
    height: 60px !important;
    margin-top: 40px;
    .ant-btn {
      height: 60px !important;
      span {
        font-size: 20px;
      }
    }
  }
}
