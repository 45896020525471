@import 'assets/css/variables';

.wrapper-main-block {
  width: 100%;
  height: calc(100vh - 48px);
  box-shadow: 0px 5px 25px $wrapper-main-block-box-shadow;
  border-radius: 20px;
  background: $wrapper-main-bg;
  box-sizing: border-box;

  .wrapper-header {
    width: 100%;
    height: 80px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $wrapper-main-df-border;
    padding: 0 48px;
    .wrapper-header-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: $wrapper-main-color;
    }
    .wrapper-header-icon {
      width: 40px;
      height: 40px;
      background: $wrapper-main-icon-color;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: $wrapper-main-box-shadow;
      border-radius: 10px;
      img,
      svg {
        object-fit: cover;
      }
    }
  }
  .wrapper-body {
    width: 100%;
    background: $wrapper-main-bg;
    padding: 24px 0;
    height: calc(100% - 80px);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    scrollbar-color: #3dc4ee #ffffff00;

    &::-webkit-scrollbar {
      width: 14px;
      scrollbar-color: #3dc4ee #ffffff00;
      scrollbar-width: thin;
    }
  }
}

@media (max-width: 1550px) {
  .wrapper-main-block .wrapper-header{
    height: 64px !important;
  }
}
