@import 'assets/css/variables';

.item-ext-id-tooltip {
 
  .ant-popover-inner-content {
    width: 190px;
    padding: 6px !important;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ext-id-row {
    flex-flow: inherit;
    display: flex;
  }
  .ext-id-popover-ext-id-type {
    color: $gray-text;
    width: 40%;
  }
  .ext-id-popover-id-value {
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .spinner-ext-id-tooltip {
    display: flex;
    justify-content: center;
  }
}

.top-position-tooltip{
  .ant-popover-content {
    right: 19px !important;
  }
}
