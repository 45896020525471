@import "assets/css/variables";

.table-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 0 24px 0 48px;
  align-items: center;
  position: relative;

  .pagination-select-info {
    position: absolute;
    display: flex;
    left: 93px;
    top: 50%;
    transform: translate(-50%, -39%);
    column-gap: 59px;
    color: $pagination-df-color;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }

  .amount-of-entries {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px;
    color: $pagination-df-color;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    top: 50%;
  }

  .ant-pagination {
    margin-right: 25px;

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      text-overflow: unset;
    }

    .ant-select-item-option-content {
      text-overflow: unset;
    }
    .ant-select-dropdown {
      min-width: 43px !important;
      border: 1px solid $pagination-select-border;
      border-radius: 5px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: $pagination-select-bg;
      color: $pagination-select-color;
    }

    .rc-virtual-list {
      width: 43px;
      margin: 0;
      .rc-virtual-list-holder {
        overflow: hidden;
      }
    }

    .ant-pagination-options-size-changer.ant-select {
      width: 51px;
      color: $pagination-ant-pagination-color;
    }

    .ant-select-arrow {
      right: 3px;
    }

    li {
      border: 1px solid $pagination-pagination-li-color;
      border-radius: 5px;
      margin-right: 3px;
      min-width: 0;
      width: 25px;
      min-height: 25px;
      height: 25px;

      .pagination-item,
      a {
        color: $pagination-pagination-item-color;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a {
        height: 23px;
      }

      .pagination-item {
        height: 24px;
      }
    }
    li:first-child,
    li:last-child {
      border: none;
    }
    .ant-pagination-next {
      border: none;
    }
    li:first-child {
      margin-right: 29px;
    }
    button {
      border: 1px solid $pagination-select-border-btn;
      background: transparent;
      border-radius: 5px;
      min-height: 25px;
      height: 25px;
      padding: 0 10px;
      color: $pagination-select-border-color;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-pagination-item-active {
      background: $pagination-select-btn-active-bg;
      .pagination-item,
      a {
        color: $pagination-select-btn-active-bg-a !important;
      }
    }
    .ant-pagination-options {
      position: absolute;
      left: 48px;

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
      }
    }
  }
  
  .ant-pagination-item-ellipsis{
    left: -6px !important;
  }

  .ant-select-dropdown {
    .ant-select-item:last-child {
      width: 46px;
    }
  }
}
