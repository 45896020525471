@import 'assets/css/variables';

.unsaved-data-modal {
  .ant-modal-content {
    height: 240px;
    min-height: auto;
    .dialog-body {
      padding-top: 32px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
        color: $orders-unsaved-data-df-color;
      }
      .buttons-container {
        display: flex;
        margin-top: 16px;
        .save-btn {
          width: auto;
          height: 40px;
          margin-right: 16px;
          .ant-btn {
            height: 40px;
          }
        }
        .cancel-btn {
          width: auto;
          height: 40px;
          .ant-btn {
            height: 40px;
            border: 1px solid $orders-unsaved-data-btn-cancel-border;
            background: $orders-unsaved-data-btn-cancel-bg;
            span{
                color: $orders-unsaved-data-btn-cancel-color;
            }
          }
        }
      }
    }
  }
}
