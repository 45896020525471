@import 'assets/css/variables';

.right-card-padding {
  .ant-collapse-content {
    padding: 0 !important;
  }
}
.btn-select {
  width: 160px;
}
.search-box {
  display: flex;
  align-items: center;
  .search-item {
    margin-left: 40px;
  }
  .search-button {
    width: 32px;
    height: 32px;
    margin-left: 25px;
    .ant-btn {
      span {
        font-size: 31px;
      }
      height: 32px;
    }
  }
}
.no-data-block {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-top: 48px;
  color: $view-inbound-no-data-color;
}

.common-table-wrapper {
  height: calc(100% - 50px);
}

.modal-table {
  .table-pagination-wrapper {
    .common-table {
      margin-top: 0;
      .ant-table .ant-table-container {
        min-width: auto;
      }
    }
  }
}

.add-item-button {
  position: absolute;
  width: 107px;
  height: 32px;
  right: 0;
  bottom: 20px;
  margin-right: 40px;
  .ant-btn {
    height: 32px;
  }
}
.header-buttons {
  display: flex;
  .receive-on-gate-btn {
    margin-right: 30px;
    width: 192px;
    height: 40px;
    .ant-btn {
      height: 40px;
      background: $view-inbound-header-btn-bg;
      border: 1px solid $view-inbound-header-btn-border;
      span {
        color: $view-inbound-header-btn-color;
      }
    }
  }
  .new-inbound-btn {
    width: 192px;
    height: 40px;
    .ant-btn {
      height: 40px;
      border: 1px solid $view-inbound-header-btn-border;
    }
  }
}

@media (max-width: 1550px) {
  .order-info-container {
    .ant-collapse-item:last-child > .ant-collapse-content {
      padding: 17px 24px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
    .form-block .order-info-block {
      height: 37px;
      padding: 5px 15px 0 15px;
      span {
        left: 15px !important;
      }
    }
    .text-area-block {
      height: 97px;
    }
    .form-block .third-line {
      margin-top: 24px;
    }
    .form-block .third-line .button-wrapper {
      height: 34px;
      .ant-btn {
        height: 34px;
        padding: 9.5px 22px;
      }
    }
  }
}
