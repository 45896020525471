@import 'assets/css/variables';

.text-area-block {
  height: 118px;
  position: relative;
  .ant-input {
    width: 100%;
    height: 100%;
    resize: none;
    border: 2px solid $tx-area-border-input;
    border-radius: 10px;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px;
    color: $tx-area-df-color;
    padding: 12px 24px;
  }

  span {
    font-family: 'Poppins';
    position: absolute;
    z-index: 3;
    left: 24px;
    transition: all 0.2s ease;
    user-select: none;
    color: $tx-area-span-color;
    p {
      display: inline-block;
      margin: 0;
    }
    .d-star {
      color: $tx-area-span-error-color;
    }
  }
  .passive-label {
    top: 15px;
  }
  .active-label {
    font-size: 11px;
    top: -8px;
    background-color: $tx-area-active-label-bg-color;
    padding: 0 4px;
    color: $tx-area-active-label-color
  }
  .placeholder {
    font-family: 'Poppins';
    position: absolute;
    z-index: 10;
    left: 24px;
    transition: all 0.2s ease;
    user-select: none;
  }
}

.readonly-text-area-container{
  .ant-input[disabled] {
    background: transparent;
    color: $input-color !important;
    border-color: $input-border;
    cursor: default;
  }
}

@media (max-width: 1550px) {
  .text-area-block{
    span{
      left: 12px !important;
    }
    .ant-input{
      padding: 12px;
    }
  }
}
