@import 'assets/css/variables';

.loc-chooser-modal {
  .ant-modal-content {
    max-height: 900px;
    height: auto;
    .ant-modal-body {
      max-height: 800px;
      .dialog-body {
        max-height: 740px;
        position: relative;
        padding: 0;
        overflow: hidden;
        .loc-modal-racks-block {
          overflow: auto;
          max-height: 700px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            // display: none;
            width: 10px;
          }
        }
        .bottom-modal-row {
          position: sticky;
          align-items: center;
          width: 810px;
          height: 60px;
          bottom: -5px;
        }
        .selected-location-bins-name {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 14px;
          color: $chooser-loc-modal-color;
          margin-top: 24px;
          margin-left: 40px;
        }
        .bins-container {
          margin-left: 40px;
          .bin-block {
            border: 1px solid $chooser-loc-modal-border-container-bin;
            border-radius: 10px;
            padding: 4px 16px;
            margin-top: 10px;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected-bin {
            border: 1px solid $blue-legenda;
          }
        }
      }
    }
  }
  .button-cirlce-wrapper {
    position: relative;
    width: 130px;
    float: right;

    .apply-modal-button {
      width: 130px;
      height: 40px;
      float: right;
      position: relative;
      z-index: 1;
      .ant-btn {
        height: 40px;
        z-index: 1;
      }
    }
    .error-message-circle {
      width: 17px;
      height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid $chooser-loc-modal-error-message-circle-border;
      background: $chooser-loc-modal-error-message-circle-bg;
      position: absolute;
      top: -2px;
      left: -2px;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 10px;
      color: $chooser-loc-modal-error-message-circle-color;
      z-index: 2;
      cursor: pointer;
    }
  }
}
.error-message-popover{
  margin-left: -20px;
}

@media (max-width: 1550px) {
  .apply-modal-button {
    height: 40px !important;
    .ant-btn {
      height: 40px !important;
    }
  }
}
